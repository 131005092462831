import { Box, Typography, Chip } from "@mui/material";
import React from "react";
import YourTimeTable from "./components/YourTimeTable";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

const Event = () => {
  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Events", link: "" },
  ];

  return (
    <Box mt={10} bgcolor={"white"} p={3}>
      <BreadCrumbs data={breadcrumb} />
      
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Upcoming events or planned events
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          All upcoming events including material pickup requests, inspection dates, tenders, and more will be shown here.
        </Typography>
        <Chip label="Under Development" color="secondary" />
      </Box>
      
      <YourTimeTable />
    </Box>
  );
};

export default Event;