import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useHistory } from "react-router-dom";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getAllMiscellaneousRequests } from "../InternalFormState";
import MiscellaneousRequestForm from "./MiscellaneousRequestForm";
import { formatIndianCurrency } from "../../../utils/currencyFormat";
import Table from "../../../components/Table/Table";
import {
  TabPanel,
  a11yProps,
  miscellaneousTableHeader,
  getStatusStyle,
} from "../components/config/tableHelper";
import SectionHeader from "../components/SectionHeader";
import { tableOptions } from "../components/config/tableConfig";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";

export default function TabPanelMiscellaneousReq() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const data = useSelector((state) => state.userdata.getData);
  const dispatch = useDispatch();
  const [miscellaneousReqs, setMiscellaneousReqs] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300); // 300ms delay

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
    searchTerm: "",
  });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const maxRowsPerPage = totalPages || 10;
    const dynamicRowsPerPageOptions = [10, 20, 50].filter(
      (option) => option <= maxRowsPerPage
    );
    if (!dynamicRowsPerPageOptions.includes(maxRowsPerPage)) {
      dynamicRowsPerPageOptions.push(maxRowsPerPage);
    }
    setRowsPerPageOptions(dynamicRowsPerPageOptions);
  }, [totalPages]);

  let tabData = [];
  let myRequests = [];
  let allRequests = [];
  let pendingRequests = [];

  if (
    data &&
    data.roles.some((item) => "user" === item) &&
    !data.roles.some((item) => "Viewer" === item) &&
    !data.roles.some((item) => "Approver" === item)
  ) {
    tabData = [{ label: t("My Requests"), index: 0, data: myRequests }];
  } else if (
    (data && data?.roles.some((item) => "Viewer" === item)) ||
    data?.roles.some((item) => "Approver" === item) ||
    data?.roles.some((item) => "Admin" === item)
  ) {
    tabData = [
      { label: t("All"), index: 0, data: allRequests },
      { label: t("Pending"), index: 1, data: pendingRequests },
    ];
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleToggleView = (data) => {
    setShowForm(data);
    fetchMiscellaneousPaymentReqs(pageData);
  };

  const handleRowClick = (rowData) => {
    const selectedId = rowData[0];
    const filteredID = miscellaneousReqs?.find(
      (otherReq) => otherReq?.misReqId === selectedId
    );
    toggleModal();
    handleViewMissReq(filteredID);
  };

  const handleViewMissReq = (data) => {
    dispatch({
      type: "SET_SELECTED_MISCELLANEOUS_REQUEST",
      payload: data,
    });
    history.push("/app/miscellaneousRequest");
  };

  // Fetch data
  const fetchMiscellaneousPaymentReqs = async (pageData) => {
    setFetchLoader(true);
    try {
      const allMiscellaneousReqs = await getAllMiscellaneousRequests(pageData);

      if (allMiscellaneousReqs.status === "200") {
        setMiscellaneousReqs(allMiscellaneousReqs.data);
        setTotalPages(allMiscellaneousReqs.totalCount);
      } else {
        setMiscellaneousReqs([]);
      }
      setFetchLoader(false);
    } catch (error) {
      toast.error("Error fetching Miscellaneous requests!");
      setFetchLoader(false);
    }
  };

  useEffect(() => {
    fetchMiscellaneousPaymentReqs({
      ...pageData,
      searchTerm: debouncedSearchTerm,
    });
    setIsModalOpen(false);
  }, [showForm, pageData.page, pageData.limit, debouncedSearchTerm]);

  const handleSearchChange = (searchText) => {
    setSearchTerm(searchText);
    setPageData((prevState) => ({
      ...prevState,
      page: 1,
    }));
  };

  return (
    <>
      <div className={classes.root}>
        <SectionHeader
          title={showForm ? "New Request" : "Miscellaneous"}
          buttonText={showForm ? "All Requests" : "New"}
          buttonAction={() => setShowForm((prev) => !prev)}
          buttonStyle={{ margin: "15px 10px" }}
        />

        {showForm ? (
          <MiscellaneousRequestForm toggleView={handleToggleView} />
        ) : (
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              {tabData.map((item, key) => (
                <Tab
                  key={key}
                  label={item.label}
                  {...a11yProps(item.index)}
                  classes={{ root: classes.tab }}
                />
              ))}
            </Tabs>
            {miscellaneousReqs &&
              miscellaneousReqs.length > 0 &&
              miscellaneousReqs.map((item, key) => {
                const statusStyle = getStatusStyle(item.status);
                const row = [
                  item?.misReqId,
                  moment(item?.date).format("DD-MM-YYYY"),
                  item?.otherOption[0]?.option,
                  item?.paymentFrom,
                  formatIndianCurrency(item?.amount),
                  item?.raisedBy[0]?.username,
                  <span style={statusStyle}>{item?.status}</span>,
                ];
                if (item?.status === "Pending") {
                  pendingRequests.push(row);
                }
                if (data._id === item.raisedBy[0]._id) {
                  myRequests.push(row);
                }
                allRequests.push(row);
              })}
            {tabData.map((item, key) => {
              return (
                <TabPanel value={value} index={item.index} key={key}>
                  <Table
                    data={item.data}
                    header={miscellaneousTableHeader}
                    name={""}
                    options={{
                      ...tableOptions(
                        fetchLoader,
                        rowsPerPageOptions,
                        setPageData,
                        handleRowClick,
                        "Miscellaneous Payments.csv",
                        totalPages,
                        pageData.page,
                        handleSearchChange
                      ),
                      tableBodyHeight: "550px",
                    }}
                  />
                </TabPanel>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
