import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Table from "../../../components/Table/Table";
import { getAllLeaveRequests } from "../InternalFormState";

import { TabPanel } from "../components/config/tableHelper";
import toast from "react-hot-toast";
export default function TabPanelEmpoyeeLeave({ allLeavesData }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const data = useSelector((state) => state.userdata.getData);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const datatableHeader = [
    {
      label: t("Employee"),
      options: { filter: true },
    },
    {
      label: t("Taken / Avail Casual Leave"),
      options: { filter: true },
    },
    {
      label: t("Taken / Avail Emergency Leave"),
      options: { filter: true },
    },
    {
      label: t("Taken / Avail Marriage Leave"),
      options: { filter: true },
    },
    {
      label: t("Extra Leaves / Month"),
      options: { filter: true },
    },
  ];

  const tabData =
    data &&
    data.roles.some((item) => "user" === item) &&
    !data.roles.some((item) => "Approver" === item)
      ? [{ label: t("My Requests"), index: 0, name: "", data: [] }]
      : [
          { label: t("All Requests"), index: 0, name: "", data: [] },
          { label: t("Pending"), index: 1, name: "", data: [] },
        ];

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  async function fetchLeaveRequests() {
    setFetchLoader(true);
    try {
      const allLeaveRequests = await getAllLeaveRequests();
      if (allLeaveRequests.status === "200") {
        setLeaveRequests(allLeaveRequests.data);
      } else {
        setLeaveRequests([]);
      }
      setFetchLoader(false);
    } catch (error) {
      setFetchLoader(false);
    }
  }

  useEffect(() => {
    fetchLeaveRequests();
    setIsModalOpen(false);
  }, [showForm]);

  const handleRowClick = (rowData) => {
    const selectedId = rowData[0];
    const selectedLeave = leaveRequests.find(
      (leaveReq) => leaveReq?.leaveId === selectedId
    );
    toggleModal();
  };

  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (allLeavesData) {
    allLeavesData.forEach((item) => {
      const extraLeaveData = item.extra_leave
        .map(
          (extra) =>
            `${extra.total_leave} days in ${monthArray[extra.month - 1]}`
        )
        .join(", ");
      const row = [
        item?.userName,
        `${item?.casual_leaves} / ${item?.casual_leave_left}`,
        `${item?.emergency_leaves} / ${item?.emergency_leave_left}`,
        `${item?.marriage_leave} / ${item?.marriage_leave_left}`,
        item.extra_leave.length > 0 ? extraLeaveData : "None",
      ];
      tabData[0].data.push(row);
    });
  }

  return (
    <>
      {tabData.map((tab, index) => (
        <TabPanel value={value} index={tab.index} key={index}>
          <Table
            data={tab.data}
            header={datatableHeader}
            name={"Leave data"}
            options={{
              filterType: "checkbox",
              print: false,
              download: false,
              filter: true,
              selectableRows: "none",
              filterType: "dropdown",
              customSort: (data, colIndex, order) => {
                return data.sort(
                  (a, b) =>
                    (a.data[colIndex].length < b.data[colIndex].length
                      ? -1
                      : 1) * (order === "desc" ? 1 : -1)
                );
              },
              tableBodyHeight: "550px",
              onRowClick: handleRowClick,
              textLabels: {
                body: {
                  noMatch: fetchLoader ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ color: "#0D6937", marginRight: "5px" }}
                      />{" "}
                      Loading...
                    </div>
                  ) : (
                    "No data found"
                  ),
                },
              },
            }}
          />
        </TabPanel>
      ))}
    </>
  );
}
