import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FileBase64 from "react-file-base64";
import CancelIcon from '@mui/icons-material/Cancel';
import { ToWords } from 'to-words';
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import Divider from "@mui/material/Divider";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BankDetailsDialog from "./BankDetailsDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import CachedIcon from "@mui/icons-material/Cached";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import InvoicePDF from "./InvoicePDF";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import {
  getFile,
  getLead,
  getAllAccounts,
  addSignature,
  getInvoiceGenId,
  assignLead,
  invoiceGeneration,
} from "../../../LeadState";

import {
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  Select,
  Grid,
  MenuItem,
  TextField,
  IconButton,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import useStyles from "../styles";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import NotificationContainer from "../../../../notifications/NotificationContainer";
import { fileupload } from "../../../../material_pickup_request/MprState";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="₹"
    />
  );
}

const CreateInvoice = (props) => {
  // eslint-disable-next-line
  var leadCustomerName = useSelector(
    (state) => state.selectedLead[0].customerName
  );

  var selectedLead = useSelector((state) => state.selectedLead[0]);
  const loginData = useSelector((state) => state.userdata);
  var invoiceDueOn = moment().add(7, "d").toDate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState(leadCustomerName);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceValue, setInvoiceValue] = useState(
    props.list.transactionAmount ? props.list.transactionAmount.amount : ""
  );
  const [invoiceSubject, setInvoiceSubject] = useState(
    `Invoice for ${selectedLead?.Mpr_id}`
  );
  const [subtotal, setSubtotal] = useState();
  const [gstAmount, setGstAmount] = useState(0);
  const [gstBreakUps, setGstBreakUps] = useState([]);
  const [otherCharges, setOtherCharges] = useState("");
  const [totalInWords, setTotalInWords] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [fileData, setFileData] = useState("");
  const [region, setRegion] = useState("INR");
  const [genratedDate, setGeneratedDate] = useState(new Date());
  const [invoiceDueDate, setInvoiceDueData] = useState(invoiceDueOn);
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);
  const [type, setType] = useState("");
  const [showloader, setShowLoader] = useState(false);
  const [res, setRes] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showStampLoader, setShowStampLoader] = useState(false);
  const [pdfAvailable, setPdfAvailable] = useState({});
  const [showPDF, setShowPDF] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceUpdateData, setInvoiceUpdateData] = useState({});
  var loggedInDatas = useSelector((state) => state.userdata);
  // let signature = loggedInDatas.getData?.signature;
  const [signature,setSignature ]= useState(loggedInDatas.getData?.signature)
  // let stamp = loggedInDatas.getData?.stamp;
  const [stamp,setStamp ]= useState(loggedInDatas.getData?.stamp)
  const [showSignature, setShowSignature] = useState(false);
  const [showStamp, setShowStamp] = useState(false);
  const toWords = new ToWords();
  //stamp and sign

  const handleChange = (type) => {
    if (
      fileData.type === "image/png" ||
      fileData.type === "image/jpeg" ||
      fileData.type === "image/jpg"
    ) {
      setShowStampLoader(true);
      var filetype = fileData.type.split("/");
      var baseData = fileData.base64.split(",");
      let getTokenData = {
        abbrevation: loginData.getData.abbrevation,
        fileType: "assets",
        folderName: props.list.external_id,
        expireLimt: 1800,
        extension: filetype.length > 0 ? filetype[1] : "",
      };
      fileupload(
        baseData.length > 0 ? baseData[1] : "",
        getTokenData,
        fileData.type
      ).then((response) => {
        if (response.status === "200") {
          let body = {
            thisItem: type,
            thisImage: {
              fileName: response.fileName || response.filename,
              folderName: response.folderName || response.folder || response.FolderName,
            },
            thisLead: props.list.external_id,
            thisForm: 'invoice',
          };
          if(type ==="Stamp"){
            setStamp({"fileName":response.fileName,"folderName":response.folderName})
          }else if(type === "Signature"){
            setSignature({"fileName":response.fileName,"folderName":response.folderName})
          }
          addSignature(body).then((resp) => {
            if (resp.status === "200") {
              setType("success");
              if (type === "Stamp") {
                setMessage("Stamp Uploaded");
                setShowStamp(true)
              } else if (type === "Signature") {
                setMessage("Signature Uploaded");
                setShowSignature(true)
              }
              setNotification(true);
              setShowStampLoader(false);
            } else {
              setType("error");
              setMessage(resp.message);
              setNotification(true);
              setShowStampLoader(false);
            }
          });
          setNotification(false);
        }
      });
    } else {
      setType("error");
      setMessage("Please upload Image");
      setNotification(true);
    }
    setNotification(false);
  };

  const handleshowhidepdf = () => {
    setShowPDF(!showPDF);
  };

  const handlepdf = () => {
    let body = {
      fileName: pdfAvailable.fileName || pdfAvailable.filename,
      folderName: pdfAvailable.folderName || pdfAvailable.folder || pdfAvailable.FolderName,
      expireLimt: 1800,
    };
    getFile(body).then((response) => {
      setInvoiceUrl(response.reference);
      setShowPDF(true);
    });
  };

  useEffect(() => {
    if (signature) {
      setShowSignature(true);
    } else {
      setShowSignature(false);
    }
  }, [signature]);

  useEffect(() => {
    if (stamp) {
      setShowStamp(true);
    } else {
      setShowStamp(false);
    }
  }, [stamp]);

  const getFiles = (file) => {
    let bodyData = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(bodyData).then((response) => {
      window.open(response.reference, "_blank");
    });
  };

  //confirmation

  const confirmOpenDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const confirmCloseDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  ///table utilities
  const columns = [
    { label: "Item Details", width: 300 },
    { label: "HSN/SAC", width: 100 },
    { label: "Quantity", width: 100 },
    { label: "Unit", width: 100 },
    { label: "Rate/Item", width: 100 },
    { label: "Amount", width: 100 },
  ];

  const initialItemsData = [[" ", " ", " ", " ", " ", " "]];
  const [itemsData, setItemsData] = useState(initialItemsData);
  const [totalItems, setTotalItems] = useState(0);
  const [customerNotes, setCustomerNotes] = useState(
    "Thank you for your business."
  );


  const [existingBankDetails, setExistingBankDetails] =
    useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  
  const getAllAccountDetails = () => {
    getAllAccounts().then((response) => {
      if (response.status === "200") {
        const activeBanks = response.data.filter((bank) => bank.isActive);
        setExistingBankDetails(activeBanks);
        const bankDetailsFormat = `BankName: ${activeBanks[0]?.bankName}\nAccount: ${activeBanks[0]?.accountNumber}\nIFSC: ${activeBanks[0]?.ifscCode}\nBranch: ${activeBanks[0]?.branchName}`;
        setSelectedBank(bankDetailsFormat)
      } else {
        setExistingBankDetails([]);
        setSelectedBank({});
        if (response.status === "401") {
          setType("error");
          setMessage(response.message);
        } else {
          setType("error");
          setMessage(response.message);
        }
      }
    });
  };
  

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleSaveBankDetails = (bankDetails) => {
    setSelectedBank(
      `BankName: ${bankDetails.bankName}\nAccount: ${bankDetails.accountNumber}\nIFSC: ${bankDetails.ifscCode}\nBranch: ${bankDetails.branchName}`
    );
  };



  /////
  const handleAddRow = () => {
    const prevRow = itemsData[itemsData.length - 1];
    if (!prevRow) {
      setItemsData([initialItemsData[0]]);
    } else {
      const isRequiredFieldsEmpty = [0, 2, 3, 4].some(
        (columnName) => !prevRow[columnName]
      );
      if (isRequiredFieldsEmpty) {
        setMessage(
          "Required fields (Item Details , Quantity , Unit , Rate/Item)"
        );
        setType("error");
        setNotification(true);
        setTimeout(() => {
          setMessage("");
          setType("");
          setNotification(false);
        }, 2500);
      } else {
        setItemsData((prevData) => [...prevData, initialItemsData[0]]);
      }
    }
  };

  const handleDeleteRow = (rowIndex) => {
    setSelectedRow(rowIndex);
    confirmOpenDialog();
  };

  const confirmAction = () => {
    const updatedData = [...itemsData];
    updatedData.splice(selectedRow, 1);
    setItemsData(updatedData);
    setSelectedRow();
  };

  function formatIndianRupees(amount) {
    return amount
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
      .replace("₹", "");
  }

  const handleCellChange = (value, rowIndex, colName) => {
    const updatedData = [...itemsData];
    if (colName === 1) {
      const capitalizedValue = value.toUpperCase();
      updatedData[rowIndex][colName] = capitalizedValue;
    } else if (colName === 2) {
      const onlyNumbers = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/;
      if (onlyNumbers.test(value)) {
        updatedData[rowIndex][colName] = value;
      } else {
        updatedData[rowIndex][colName] = "";
      }
    } else if (colName === 3) {
      const allowedCharacters = /^[a-zA-Z /]*$/;
      if (allowedCharacters.test(value)) {
        updatedData[rowIndex][colName] = value;
      } else {
        updatedData[rowIndex][colName] = "";
      }
    }
    else if (colName === 4){
      const parsedValue = parseFloat(value);
      const onlyNumbers = /^\d{1,10}(\.\d{1,2})?$/;
      if (onlyNumbers.test(parsedValue)) {
        updatedData[rowIndex][colName] = value;
      } 
      else if (isNaN(parsedValue)){
        updatedData[rowIndex][colName] = " ";
      }
      else {
        var roundedValue = parsedValue.toFixed(2);
        updatedData[rowIndex][colName] = roundedValue;
      }
    }else if (colName === 5) {
      const parsedValue = parseFloat(value);
      updatedData[rowIndex][colName] = !isNaN(parsedValue) ? parsedValue : "";
    } else {
      updatedData[rowIndex][colName] = value;
    }

    setItemsData(updatedData);
  };

  const calculateAmountForAitem = (rowIndex) => {
    const quantity = parseFloat(itemsData[rowIndex][2]);
    const ratePerQuantity = parseFloat(itemsData[rowIndex][4]);
    if (!isNaN(ratePerQuantity) && !isNaN(quantity)) {
      const amount = quantity * ratePerQuantity;
      itemsData[rowIndex][5] = amount;
      return formatIndianRupees(amount);
    } else {
      itemsData[rowIndex][5] = "";
      return "";
    }
  };

  //final calculations
  function calculateTotalItemsCount(itemsData) {
    return itemsData.length;
  }
  //subtotal
  function calculateSubTotalAmount(itemsData) {
    let subTotalAmount = 0;
    itemsData.forEach((row) => {
      const rowAmount = parseFloat(row[5]);
      if (!isNaN(rowAmount)) {
        subTotalAmount += rowAmount;
      }
    });

    return subTotalAmount;
  }

  ////gst

  const [cgstPercentage, setCgstPercentage] = useState("9");
  const [cgstAmount, setCgstAmount] = useState("0.00");
  const [sgstPercentage, setSgstPercentage] = useState("9");
  const [sgstAmount, setSgstAmount] = useState("0.00");
  const [igstPercentage, setIgstPercentage] = useState("0");
  const [igstAmount, setIgstAmount] = useState("0.00");

  const handlePercentageChange = (
    gstType,
    newValue,
    setPercentage,
    setAmount
  ) => {
    const isValidInput =
      /^\d{0,3}(\.\d{0,2})?$/.test(newValue) &&
      parseFloat(newValue) >= 0 &&
      parseFloat(newValue) <= 100;
    if (isValidInput || newValue === "") {
      setPercentage(newValue);
      const calculatedAmount =
        (parseFloat(subtotal) * parseFloat(newValue)) / 100;
      setAmount(calculatedAmount);
      if (newValue !== "") {
        if (gstType === "cgst" || gstType === "sgst") {
          setIgstPercentage("0");
          setIgstAmount("0");
        } else if (gstType === "igst") {
          setCgstPercentage("0");
          setCgstAmount("0");
          setSgstPercentage("0");
          setSgstAmount("0");
        }
      }
    }
  };

  const handleCgstChange = (newValue) => {
    handlePercentageChange("cgst", newValue, setCgstPercentage, setCgstAmount);
  };

  const handleSgstChange = (newValue) => {
    handlePercentageChange("sgst", newValue, setSgstPercentage, setSgstAmount);
  };

  const handleIgstChange = (newValue) => {
    handlePercentageChange("igst", newValue, setIgstPercentage, setIgstAmount);
  };

  //total
  const invoiceValueInWords = (amount) => {
    const numericValue = parseFloat(amount);
    if (!isNaN(numericValue)) {
      const inrWords = toWords.convert(amount,{currency:'true'});
      const [beforeRupees, afterRupees] = inrWords.split("Rupees ");
      const modifiedText = `Rupees ${beforeRupees}${afterRupees}`
      setTotalInWords(modifiedText);
    } else if (!invoiceValue) {
      setTotalInWords("");
    } else {
      setTotalInWords("Invalid Amount");
    }
  };
  const roundOffTotal = () => {
    const roundedAmount = Math.round(invoiceValue);
    const formattedAmount = roundedAmount.toFixed(2);
    setInvoiceValue(formattedAmount);
    const inrWords = toWords.convert(roundedAmount,{currency:'true'});
    const [beforeRupees, afterRupees] = inrWords.split("Rupees ");  
    const modifiedText = `Rupees ${beforeRupees}${afterRupees}`
    setTotalInWords(modifiedText);
  };

  const calculateGST = (subtotal, percentage) => {
    const gst=(parseFloat(subtotal) * parseFloat(percentage)) / 100;
    return gst.toFixed(2)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let payload = {
          leadID: selectedLead?.external_id,
        };
        const data = await getInvoiceGenId(payload);
        if (data.status === "200") {
          setInvoiceNo(data.data);
        } else if (data.status === "500") {
          setInvoiceNo(`INV-${selectedLead.Mpr_id}`);
        }
      } catch (error) {
        setInvoiceNo(`INV-${selectedLead.Mpr_id}`);
      }
    };

    fetchData();
    getAllAccountDetails();
  }, [selectedLead]);

  useEffect(() => {
    const totalItemsCount = calculateTotalItemsCount(itemsData);
    const subTotalAmount = calculateSubTotalAmount(itemsData);
    const otherChargesIncluded = otherCharges;
    const calculateCgst = calculateGST(subTotalAmount, cgstPercentage);
    setCgstAmount(calculateCgst);
    const calculateSgst = calculateGST(subTotalAmount, sgstPercentage);
    setSgstAmount(calculateSgst);
    const calculateIgst = calculateGST(subTotalAmount, igstPercentage);
    setIgstAmount(calculateIgst);

    let totalAmount = 0;

    let gst = parseFloat(calculateCgst) + parseFloat(calculateSgst) + parseFloat(calculateIgst);

    if (otherChargesIncluded !== "") {
      totalAmount =
        parseFloat(subTotalAmount) + parseFloat(gst) + parseFloat(otherChargesIncluded);
    } else {
      totalAmount = parseFloat(subTotalAmount) + parseFloat(gst);
    }
    setTotalItems(totalItemsCount);
    setSubtotal(formatIndianRupees(subTotalAmount));
    setInvoiceValue(totalAmount.toFixed(2));
    invoiceValueInWords(parseFloat(totalAmount.toFixed(2)));

    setGstAmount(gst);
    setGstBreakUps([
      {
        cgstPercentage: cgstPercentage,
        cgstAmount: calculateCgst,
      },
      {
        sgstPercentage: sgstPercentage,
        sgstAmount: calculateSgst,
      },
      {
        igstPercentage: igstPercentage,
        igstAmount: calculateIgst,
      },
    ]);

    if (totalAmount == 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [itemsData, otherCharges, cgstPercentage, sgstPercentage, igstPercentage]);

  const handleupdateImage = (e) => {
    setFileData(e);
  };
  const handleGenDate = (date) => {
    setGeneratedDate(date);
  };
  const handleInvoiceDueDate = (date) => {
    setInvoiceDueData(date);
  };
  const handleInvoiceSubChange = (e) => {
    setInvoiceSubject(e.target.value);
  };

  const handleClose = () => {
    props.close();
  };

  const generateInvoice = async (Data) => {
    try {
      const response = await invoiceGeneration(Data);
      if (response.status === "200") {
        const updatedData = {
          external_id: Data.external_id,
          leadData: {
            invoice: {
              // fileName: `invoice-${Data.leadData.invoice.fileName}.pdf`,
              // folderName: Data.leadData.invoice.folderName,
              fileName:response.invoiceLoc.fileName || response.invoiceLoc.filename,
              folderName:response.invoiceLoc.folderName || response.invoiceLoc.FolderName || response.invoiceLoc.folder,
              invoiceNumber: Data.leadData.invoiceDetails.invoiceNo,
              generatedOn: Data.leadData.invoiceDetails.genratedDate,
              amount: Data.leadData.invoiceDetails.invoiceValue,
            },
            status: "Invoice available",
          },
          mpr_id: props.list.Mpr_id,
        };
        setInvoiceUpdateData(updatedData);
        setType("success");
        setMessage("Invoice pdf generated");
        setNotification(true);
        setPdfAvailable(response.invoiceLoc);
      } else {
        setType("error");
        setMessage("Something went wrong with invoice generation");
        setNotification(true);
      }
    } catch (error) {
      setType("error");
      setMessage("Something went wrong with invoice generation");
      setNotification(true);
    } finally {
      setSubmit(false);
      setShowLoader(false);
    }
  };

  const handleSubmit = (event) => {
    const items = itemsData.map((item, index) => {
      return {
        itemNo: index + 1,
        itemDetails: item[0],
        HSNCode: item[1],
        quantity: item[2],
        unit: item[3],
        ratePerUnit: item[4],
        amount: item[5],
      };
    });

    const bankDetails = {};

    selectedBank.split("\n").forEach((line) => {
      const [key, value] = line.split(": ");
      bankDetails[key] = value;
    });

    const invoiceDetails = {
      customer,
      invoiceNo,
      genratedDate,
      invoiceDueDate,
      invoiceSubject,
      items,
      totalItems,
      customerNotes,
      termsAndConditions,
      subtotal,
      gstAmount,
      gstBreakUps,
      otherCharges,
      region,
      invoiceValue,
      totalInWords,
      bankDetails,
    };

    setSubmit(true);
    let Data = {
      external_id: props.list.external_id,
      leadData: {
        // invoice: {
        //   // fileName: props.list.external_id,
        //   // folderName: "invoice/" + props.list.external_id,
        //   invoiceNumber: invoiceNo,
        //   generatedOn: genratedDate,
        //   amount: invoiceValue,
        // },
        invoiceDetails,
        status: "Invoice available",
      },
      mpr_id: props.list.Mpr_id,
    };
    setShowLoader(true);
    generateInvoice(Data);
  };

  const handleSendInvoice = async (updatedData) => {
    assignLead(updatedData, dispatch).then((response) => {
      if (response?.status === "200") {
        setType("success");
        setMessage("Invoice sent Successfully");
        setNotification(true);
        setTimeout(() => props.close(), 2000);
        setSubmit(false);
      } else {
        setType("error");
        setMessage("Something went wrong");
        setNotification(true);
        setSubmit(false);
      }
    });
  };

  useEffect(() => {}, [fileData, notification]);

  const handleEditSignature = () => {
    setShowSignature(false);
  };

  const handleEditStamp =()=>{
    setShowStamp(false);
  }

  const handleCloseSignature=()=>{
    setShowSignature(true);
  }

  const handleCloseStamp=()=>{
    setShowStamp(true);
  }

  return (
    <>
      {notification ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      <div className={classes.invoiceForm}>
        <form autoComplete="off">
          <h2>{t("New Invoice")} </h2>
          {pdfAvailable?.fileName && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <div>
                Preview:
                <IconButton
                  className={classes.collapseIcon}
                  onClick={handlepdf}
                  size="large"
                >
                  <PictureAsPdfIcon />
                </IconButton>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleSendInvoice(invoiceUpdateData)}
                  // disabled={isDisabled}
                >
                  {t("common_send")} {t("invoice")}
                </Button>
              </div>
            </div>
          )}

          {showPDF ? (
            <div>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.buttonOutlined}
                onClick={handleshowhidepdf}
                style={{marginBottom:"10px",marginLeft:"-1px"}}
              >
                {t("common_close")}
              </Button>
              <InvoicePDF url={invoiceUrl} close={handleshowhidepdf} />
            </div>
          ) : (
            <>
              <div>
                <TextField
                  className={classes.formControl}
                  type="text"
                  minRows={2}
                  label={t("Customer Name")}
                  value={customer}
                  onChange={(e) => setCustomer(e.target.value)}
                  id="full_name"
                  name="customer_name"
                  InputLabelProps={{ shrink: true }}
                  required
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <Grid container spacing={2}>
                <Grid item lg={5} md={12} sm={12} xs={12}>
                  <div>
                    <TextField
                      className={classes.formControl}
                      type="text"
                      // defaultValue={"INV/001/2023-24/"}
                      label={t("Invoice#")}
                      value={invoiceNo}
                      InputLabelProps={{ shrink: true }}
                      // onChange={(e) => setInvoiceNo(e.target.value)}
                      id="invoice_no"
                      name="invoice_no"
                      required
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg={5} md={4} sm={12} xs={12}>
                  <div className={classes.formControl}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        margin="normal"
                        id="date-picker-dialog"
                        label={t("Invoice Date")}
                        format="dd/MM/yyyy"
                        value={genratedDate}
                        fullWidth
                        readOnly
                        onChange={handleGenDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item lg={5} md={4} sm={12} xs={12}>
                  <div className={classes.formControl}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        margin="normal"
                        id="date-picker-dialog"
                        label={t("Due date")}
                        format="dd/MM/yyyy"
                        value={invoiceDueDate}
                        fullWidth
                        disablePast
                        onChange={handleInvoiceDueDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
              </Grid>

              <div>
                <FormControl
                  variant="standard"
                  fullWidth
                  className={classes.formControl}
                >
                  <TextField
                    id="product_des"
                    required={true}
                    label={t("Subject")}
                    slotProps={{ textField: { variant: "standard" } }}
                    multiline
                    placeholder="Let your customer know what this invoice is for"
                    minRows={2}
                    value={invoiceSubject}
                    onChange={handleInvoiceSubChange}
                  />
                </FormControl>
              </div>

              {/* items details,hsn/sac,,quantity,rate/item,taxable value,tax amount,amount */}
              <div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        {columns.map((column, colIndex) => (
                          <TableCell
                            key={colIndex}
                            sx={{
                              padding: "6px 4px",
                              width: column.width,
                              fontWeight: "bold",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell style={{ width: 10 }}>Actions</TableCell>
                      </TableRow>
                      {itemsData.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {row.map((cell, colIndex) => (
                            <TableCell key={colIndex} sx={{ padding: "4px" }}>
                              <TextField
                                multiline={colIndex === 0 ? true : false}
                                fullWidth
                                sx={{
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                      display: "none",
                                    },
                                  "& input[type=number]": {
                                    MozAppearance: "textfield",
                                  },
                                  padding: "0px",
                                  width: columns[colIndex]
                                    ? columns[colIndex].width
                                    : "100%",
                                }}
                                InputProps={{
                                  inputProps: {
                                    autoComplete: "off",
                                    style: { resize: "both" },
                                  },
                                }}
                                value={
                                  colIndex === 5
                                    ? calculateAmountForAitem(rowIndex)
                                    : cell
                                }
                                onChange={(e) =>
                                  handleCellChange(
                                    e.target.value,
                                    rowIndex,
                                    colIndex
                                  )
                                }
                              />
                            </TableCell>
                          ))}

                          <TableCell>
                            <IconButton
                              onClick={() => handleDeleteRow(rowIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div
                  style={{
                    margin: "6 0",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button onClick={handleAddRow}>
                    <AddCircleIcon style={{ color: "#0D6937" }} />
                    <span style={{ fontSize: "16px", marginTop: "2px" }}>
                      Add Item
                    </span>
                  </Button>
                  <div style={{ padding: "10px" }}>
                    Total Items: {totalItems}
                  </div>
                </div>
              </div>

              {/* price calculations */}

              <Grid container spacing={1}>
                <Grid item lg={6} md={6} xs={1} sm={1}>
                  {" "}
                </Grid>
                <Grid item lg={6} md={6} xs={11} sm={11}>
                  <TextField
                    className={classes.formControl}
                    label={t("Taxable Value")}
                    value={subtotal}
                    onChange={(e) => setSubtotal(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input-subtotal"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* gst calculations */}
                <Grid item lg={6} md={6} xs={1} sm={1}>
                  {" "}
                </Grid>
                <Grid item lg={2} md={2} xs={5} sm={5}>
                  <TextField
                    className={classes.formControl}
                    label={t("CGST (%)")}
                    value={cgstPercentage}
                    onChange={(e) => handleCgstChange(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input-cgst"
                    InputLabelProps={{ shrink: true }}
                    disabled={Boolean(gstAmount.igst)}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6} sm={6}>
                  <TextField
                    className={classes.formControl}
                    label={t("CGST Amount")}
                    value={cgstAmount}
                    name="numberformat"
                    id="formatted-numberformat-input-cgst"
                    InputLabelProps={{ shrink: true }}
                    disabled={Boolean(gstAmount.igst)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={1} sm={1}>
                  {" "}
                </Grid>
                <Grid item lg={2} md={2} xs={5} sm={5}>
                  <TextField
                    className={classes.formControl}
                    label={t("SGST (%)")}
                    value={sgstPercentage}
                    onChange={(e) => handleSgstChange(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input-sgst"
                    InputLabelProps={{ shrink: true }}
                    disabled={Boolean(gstAmount.igst)}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6} sm={6}>
                  <TextField
                    className={classes.formControl}
                    label={t("SGST Amount")}
                    value={sgstAmount}
                    name="numberformat"
                    id="formatted-numberformat-input-sgst"
                    InputLabelProps={{ shrink: true }}
                    disabled={Boolean(gstAmount.igst)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={1} sm={1}>
                  {" "}
                </Grid>
                <Grid item lg={2} md={6} xs={5} sm={5}>
                  <TextField
                    className={classes.formControl}
                    label={t("IGST (%)")}
                    value={igstPercentage}
                    onChange={(e) => handleIgstChange(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input-igst"
                    InputLabelProps={{ shrink: true }}
                    disabled={Boolean(gstAmount.cgst || gstAmount.sgst)}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6} sm={6}>
                  <TextField
                    className={classes.formControl}
                    label={t("IGST Amount")}
                    value={igstAmount}
                    name="numberformat"
                    id="formatted-numberformat-input-igst"
                    InputLabelProps={{ shrink: true }}
                    disabled={Boolean(gstAmount.cgst || gstAmount.sgst)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={1} sm={1}></Grid>
                <Grid item lg={6} md={6} xs={11} sm={11}>
                  <TextField
                    className={classes.formControl}
                    label={t("Other Charges")}
                    value={otherCharges}
                    onChange={(e) => setOtherCharges(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input-other-charges"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={12} xs={1} sm={1}></Grid>
                <Grid item lg={2} md={6} xs={4} sm={4}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    style={{ marginTop: "12px" }}
                  >
                    <InputLabel id="state_drop">{t("Currency")}</InputLabel>
                    <Select
                      label={t("Currency")}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      labelId="state_drop"
                      id="region"
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                    >
                      <MenuItem value={"INR"}>INR</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} xs={7} sm={7}>
                  <TextField
                    className={classes.formControl}
                    label={t("Total Amount")}
                    value={invoiceValue}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Round Off">
                            <IconButton onClick={roundOffTotal} edge="end">
                              <CachedIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ style: { fontSize: 20, fontWeight: "700" } }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={0} sm={0}>
                  {" "}
                </Grid>
                <Grid item lg={8} md={8} xs={12} sm={12}>
                  <TextField
                    className={classes.formControl}
                    label={t("Total Amount in Words")}
                    value={totalInWords}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
              {/*  */}
              <Grid container spacing={2} my={1}>
                <Grid item lg={4} md={4} xs={0} sm={0}></Grid>
                <Grid item lg={8} md={8} xs={12} sm={12}>
                  <TextField
                    className={classes.formControl}
                    type="text"
                    multiline
                    minRows={4}
                    label={t("Selected Bank Details")}
                    value={selectedBank}
                    name="bankDetails"
                    id="bankDetailsId"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={setDialogOpen} variant="outlined">
                      Select Bank details
                    </Button>
                  </Box>
                  <BankDetailsDialog
                    open={isDialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onSave={handleSaveBankDetails}
                    existingBankDetails={existingBankDetails}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <TextField
                    className={classes.formControl}
                    type="text"
                    multiline
                    label={t("Customer Notes")}
                    value={customerNotes}
                    onChange={(e) => setCustomerNotes(e.target.value)}
                    name="customerNotes"
                    id="customerNotesId"
                  />
                </Grid>
              </Grid>
              <div>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <TextField
                      className={classes.formControl}
                      type="text"
                      multiline
                      placeholder="Enter the terms and conditions of your bussiness"
                      minRows={4}
                      label={t("Terms & Conditions")}
                      value={termsAndConditions}
                      onChange={(e) => setTermsAndConditions(e.target.value)}
                      name="customerNotes"
                      id="customerNotesId"
                    />
                  </Grid>
                </Grid>
              </div>

              {/* -----upload stamp and signature----- */}
              <div className={classes.fileTypeInputTable1}>
                <Grid container spacing={3}>
                  {showStamp ? (
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "right" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Typography variant="h6"> Stamp: </Typography>
                          <IconButton
                            onClick={() => getFiles(stamp)}
                            target="_blank"
                            className={classes.collapseIcon}
                            size="large"
                          >
                            <ImageIcon />
                          </IconButton>
                          <IconButton onClick={handleEditStamp}>
                            <EditIcon className={classes.collapseIcon} size="large" />
                          </IconButton>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        lg={12} md={12} sm={12} xs={12}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                        <Grid item lg={5} md={5} sm={0} xs={0}></Grid>  
                        <Grid item lg={7} md={7} sm={12} xs={12}>
                          <Grid item lg={4} md={4} sm={12} xs={12} style={{display:"flex",alignItems:"center"}}>
                           <Typography
                            variant="p"
                            style={{ marginRight: "6px" }}
                           >
                            Upload Stamp:
                          </Typography>
                           <IconButton onClick={handleCloseStamp}>
                             <CancelIcon className="close" />
                          </IconButton> 
                          </Grid>
                          <Grid item lg={8} md={8} sm={12} xs={12} style={{display:"flex",marginTop:"10px"}}>
                          <FormControl
                            variant="standard"
                          >
                            <FileBase64
                              className={classes.input}
                              multiple={false}
                              onDone={(e) => handleupdateImage(e)}
                            />
                          </FormControl>
                          </Grid>
                          <Grid item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ textAlign: "right" }}>
                          {showStampLoader ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => handleChange("Stamp")}
                              className={classes.button}
                              style={{ marginRight: "0px" }}
                            >
                              {t("common_upload")}
                            </Button>
                          )}
                          </Grid>
                          <Grid  
                            item
                            lg={12}
                            md={12}
                            sm={12}
                             xs={12} style={{marginTop:"5px",marginBottom:"5px"}}>
                            <Divider style={{ backgroundColor: 'black', height: '1px'}}/>
                           </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
              {/*  */}
              <div className={classes.fileTypeInputTable1}>
                <Grid container spacing={3}>
                  {showSignature ? (
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "right" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Typography variant="h6">Signature: </Typography>
                          <IconButton
                            onClick={() => getFiles(signature)}
                            target="_blank"
                            className={classes.collapseIcon}
                            size="large"
                          >
                            <ImageIcon />
                          </IconButton>
                          <IconButton onClick={handleEditSignature}>
                            <EditIcon className={classes.collapseIcon} size="large" />
                          </IconButton>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Grid item lg={5} md={5} sm={0} xs={0}></Grid>  
                          <Grid item lg={7} md={7} sm={12} xs={12}>
                          <Grid item lg={4} md={4} sm={12} xs={12} style={{display:"flex",alignItems:"center"}}>
                          <Typography
                            variant="p"
                            style={{ marginRight: "6px" }}
                          >
                            Upload Signature:
                          </Typography>
                          <IconButton onClick={handleCloseSignature}>
                            <CancelIcon className="close" />
                          </IconButton>
                          </Grid>
                          <Grid item lg={8} md={8} sm={12} xs={12} style={{display:"flex",marginTop:"10px"}}>
                          <FormControl
                            variant="standard"
                            style={{ padding: "4px 0" }}
                          >
                            <FileBase64
                              multiple={false}
                              onDone={(e) => handleupdateImage(e)}
                            />
                          </FormControl>
                          </Grid>
                          <Grid item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ textAlign: "right" }}>
                          {showStampLoader ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => handleChange("Signature")}
                              className={classes.button}
                              style={{ marginRight: "0px" }}
                            >
                              {t("common_upload")}
                            </Button>
                          )}
                          </Grid>
                          <Grid  
                            item
                            lg={12}
                            md={12}
                            sm={12}
                             xs={12} style={{marginTop:"5px",marginBottom:"5px"}}>
                            <Divider style={{ backgroundColor: 'black', height: '1px'}}/>
                           </Grid>
                          </Grid> 
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
              <div style={{ marginTop: "2rem", float: "right" }}>
                {showloader ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    {t("Generate Invoice")}
                  </Button>
                )}
              </div>
            </>
          )}
        </form>

        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={confirmCloseDialog}
          onConfirm={confirmAction}
          title="Delete"
          message="Are you sure you want to delete this item?"
          confirmText="Confirm"
        />
      </div>
    </>
  );
};

export default CreateInvoice;
