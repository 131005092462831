import React, { useEffect, useState } from "react";
import { Typography, CircularProgress } from "@mui/material";
import {
  getEmployeeDashboardData,
  getDashboardData,
} from "../InternalFormState";
import { LeaveDash } from "./LeaveDash";
import { useSelector } from "react-redux";
import DashCard from "../components/DashCard";
import toast from "react-hot-toast";

export const EmployeeDash = () => {
  const loggedInUser = useSelector((state) => state.userdata.getData);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [loader, setLoader] = useState(false);
  const [employeeLeaveData, setEmployeeLeaveData] = useState([]);
  const [pendingData, setPendingData] = useState({});
  async function getTheEmployeeDashboardData({ selectedYear, userId }) {
    setLoader(true);
    try {
      const data = {
        year: selectedYear,
        userId,
      };
      const response = await getEmployeeDashboardData(data);
      if (response.status === "200") {
        setEmployeeLeaveData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error("An error occurred. Please try again.");
    }
  }

  useEffect(() => {
    if (loggedInUser && loggedInUser.roles.some((item) => "Viewer" === item)) {
      getTheDashboardData();
    }
  }, [loggedInUser]);

  async function getTheDashboardData() {
    setLoader(true);
    try {
      let data = {
        year: currentYear,
      };

      try {
        const response = await getDashboardData(data);
        if (response.status === "200") {
          const amountsByYear = {};
          const year = response.data.year;
          const pendingReimbursementPayments =
            response.data.reimbursement_payment.pending;
          const approvedReimbursementPayments =
            response.data.reimbursement_payment.approved;
          const amountsForReimbursementYear = new Array(12).fill(0);
          response.data.reimbursement_payment.months.forEach((monthData) => {
            const { month, total_amount } = monthData;
            amountsForReimbursementYear[month - 1] = total_amount;
          });
          amountsByYear["reimbursement"] = amountsForReimbursementYear;
          setPendingData({
            pendingReimbursementPayments,
            approvedReimbursementPayments,
          });
          setLoader(false);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  }

  useEffect(() => {
    getTheEmployeeDashboardData({
      selectedYear: currentYear,
      userId: loggedInUser?._id,
    });
  }, []);

  return (
    <div style={{ margin: "24px" }}>
      <div>
        {loader ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              gap: 2,
            }}
          >
            <CircularProgress />
            Loading...
          </div>
        ) : (
          <>
            {loggedInUser &&
              loggedInUser.roles.some((item) => "Viewer" === item) && (
                <div style={{ marginBottom: "24px" }}>
                  <Typography variant="h5" sx={{ marginBottom: "18px" }}>
                    Awaiting approval
                  </Typography>
                  <DashCard
                    heading="Reimbursements"
                    value={pendingData.pendingReimbursementPayments}
                  />
                </div>
              )}

            <Typography variant="h5" sx={{ marginBottom: "18px" }}>
              Your Leaves
            </Typography>
            <LeaveDash leaveData={employeeLeaveData} showLeavePolicy={true} />
          </>
        )}
      </div>
    </div>
  );
};
