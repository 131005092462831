import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  Grid,
} from "@mui/material";
// import { languageList } from "../../data/config";
import { Person as AccountIcon } from "@mui/icons-material";
// import TranslateIcon from "@mui/icons-material/Translate";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import { logout } from "../../pages/LoginContainer/LoginState";
import SideDrawer from "../Sidebar/SideDrawer";
import { getRequestFormsNotifications } from "./HeaderState";
import { NotificationBell } from "../Notification/NotificationBell";

export default function Header(props) {
  // const { t, i18n } = useTranslation();
  var classes = useStyles();
  const dispatch = useDispatch();
  var [profileMenu, setProfileMenu] = useState(null);
  // const [language, setLanguage] = useState("en");
  const [notificationLists, setNotificationLists] = useState();

  // const change = (event) => {
  //   i18n.changeLanguage(event.target.value);
  // };

  var data = props.data;

  const [notificationStatus, setNotificationStatus] = useState("");

  const handleNotifyFromChild = (status) => {
    setNotificationStatus(status);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const bodyData = { role: " " };
        if (data && data.getData) {
          bodyData.role = data.getData.roles;
        }
        const response = await getRequestFormsNotifications(bodyData);
        if (response.status === 200) {
          setNotificationLists(response);
        } else {
          console.error(
            "Error fetching notifications: Unexpected status",
            response?.status
          );
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [data, notificationStatus]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <SideDrawer />

        <Typography variant="h6" weight="medium" className={classes.logotype}>
          {data && data.getData ? data.getData.customerName : "Binbag CRM"}
        </Typography>

        <div className={classes.grow} />

        <NotificationBell
          notifyData={notificationLists}
          sendNotifyToParent={handleNotifyFromChild}
        />

        {/* <InputLabel id="Language"></InputLabel>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          classes={{ root: classes.headerIcon }}
          size="large"
        >
          <TranslateIcon />
        </IconButton>  */}

        {/* <Select
          variant="standard"
          labelId="Language"
          id="LanguageSelect"
          value={language}
          onChange={change}
          style={{ color: "#fff" }}
        >
          {languageList.map((item, key) => {
            return (
              <MenuItem
                value={item.value}
                onClick={() => setLanguage(item.value)}
                key={key}
              >
                {item.lable} {item.trans}
              </MenuItem>
            );
          })}
        </Select> */}
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
          size="large"
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          // className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {data.getData ? data.getData.userName : ""}
            </Typography>
            <Typography component="a" color="primary">
              {data.getData ? data.getData.email : ""}
            </Typography>
           
          </div>
          <Grid>
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                onClick={() => logout(dispatch)}
              >
                Sign out
              </Typography>
            </div>
          </Grid>

          <Grid></Grid>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
