import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const fields = [
  { key: "total", label: "Total" },
  { key: "pending", label: "Pending" },
  { key: "rejected", label: "Rejected" },
  { key: "approved", label: "Approved" },
  { key: "partial", label: "Partial" },
  { key: "completed", label: "Completed" },
  { key: "sold", label: "Sold" },
  { key: "closed", label: "Closed" },
  { key: "advance disbursed", label: "Advance Disbursed" },
  { key: "expense report submitted", label: "Expense Report Submitted" },
  { key: "reupload expense report", label: "Reupload Expense Report" },
  { key: "payable to user", label: "Payable to User" },
  { key: "receivable by company", label: "Receivable by Company" },
];

const colors = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7300",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#a4de6c",
  "#d0ed57",
  "#ffc658",
  "#ff7300",
  "#8dd1e1",
  "#a4de6c",
  "#d0ed57",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function DashCard({ title, data, loggedinUser }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const chartData = fields
    .filter((field) => {
      if (loggedinUser?.roles?.includes("Admin")) {
        const allowedFields = [
          "total",
          "approved",
          "partial",
          "payable to user",
        ];
        return allowedFields.includes(field.key);
      } else if (
        loggedinUser?.roles?.includes("Approver") &&
        loggedinUser?.roles?.includes("user")
      ) {
        const allowedFields = [
          "total",
          "pending",
          "completed",
          "sold",
          "advance disbursed",
          "expense report submitted",
          "reupload expense report",
          "receivable by company",
        ];
        return allowedFields.includes(field.key);
      } else if (loggedinUser?.roles?.includes("user")) {
        const allowedFields = [
          "total",
          "pending",
          "completed",
          "advance disbursed",
          "reupload expense report",
          "payable to user",
          "receivable by company",
        ];
        return allowedFields.includes(field.key);
      } else {
        return false;
      }
    })
    .filter(
      (field) => data[field.key] !== undefined && data[field.key] !== null
    )
    .map((field) => ({
      name: field.label,
      value: data[field.key],
    }));

  const outerRadius = isMobile ? 100 : 150;
  const innerRadius = isMobile ? 40 : 60;

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {title}
        </Typography>
        <ResponsiveContainer width="100%" height={isMobile ? 400 : 400}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              layout={isMobile ? "horizontal" : "vertical"}
              align={isMobile ? "center" : "right"}
              verticalAlign={isMobile ? "bottom" : "middle"}
            />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
