import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const EventDialog = ({ open, onClose, onSave, event, slotInfo }) => {
  const [title, setTitle] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [notes, setNotes] = useState("");
  const [color, setColor] = useState("#3174ad"); // Default color

  const colorOptions = [
    { value: "#3174ad", label: "Blue" },
    { value: "#32a852", label: "Green" },
    { value: "#a83232", label: "Red" },
    { value: "#a87d32", label: "Orange" },
    { value: "#8132a8", label: "Purple" },
  ];

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setStart(event.start.toISOString().slice(0, 16));
      setEnd(event.end.toISOString().slice(0, 16));
      setNotes(event.notes || "");
      setColor(event.color || "#3174ad");
    } else if (slotInfo) {
      setTitle("");
      setStart(slotInfo.start.toISOString().slice(0, 16));
      setEnd(slotInfo.end.toISOString().slice(0, 16));
      setNotes("");
      setColor("#3174ad");
    }
  }, [event, slotInfo]);

  const handleSave = () => {
    onSave({ title, start: new Date(start), end: new Date(end), notes, color });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{event ? "Edit Event" : "Add New Event"}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="normal"
          label="Event Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div style={{ display: "flex", gap: "16px" }}>
          <TextField
            margin="normal"
            label="Start Time"
            type="datetime-local"
            fullWidth
            value={start}
            onChange={(e) => setStart(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            label="End Time"
            type="datetime-local"
            fullWidth
            value={end}
            onChange={(e) => setEnd(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <TextField
          margin="normal"
          label="Notes"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          variant="filled"
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Event Color</FormLabel>
          <RadioGroup
            row
            aria-label="event-color"
            name="event-color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          >
            {colorOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio style={{ color: option.value }} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;