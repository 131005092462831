import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import {
  Typography,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "../../styles";

import { formatIndianCurrency } from "../../../../utils/currencyFormat";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import moment from "moment";
import {
  actionOnPaymentRequest,
  getFile,
  getPaymentHistoryStatus,
} from "../../InternalFormState";
import StatusHistory from "../../components/StatusHistory";
import HeaderComp from "../../components/HeaderComp";
import { PAYMENT_STATUS } from "../../components/config/contants";
import RenderAttachments from "../../components/RenderAttachments";
import toast, { Toaster } from "react-hot-toast";

const EditVendorRequest = () => {
  const vendorPaymentStatus = [
    "Pending",
    "Approved",
    "Partial Payment",
    "New Partial Payment",
    "Payment Completed",
    "Rejected",
  ];
  var classes = useStyles();
  const vendorData = useSelector((state) => state.selectedVendorPayment);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedData, setSelectedData] = useState(vendorData);
  const loggedInData = useSelector((state) => state.userdata.getData);
  const [selectedStatus, setSelectedStatus] = useState(selectedData?.status);
  const [isViewer, setIsViewer] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isAuthorizer, setIsAuthorizer] = useState(false);
  const [isOnlyUser, setIsOnlyUser] = useState(false);
  const [isSameApprover, setIsSameApprover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusHistory, setStatusHistoy] = useState([]);
  const { t } = useTranslation();

  //confirm dialog box
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedStatus(selectedData?.status);
  };

  const handleConfirmDialog = (
    confirm,
    approverComments,
    rejectionReason,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid
  ) => {
    if (confirm) {
      takeActionOnPaymentRequest(
        selectedData,
        selectedStatus,
        rejectionReason,
        approverComments,
        transactionID,
        adminComments,
        uploadedFiles,
        amountPaid
      );
    } else {
      setSelectedStatus(selectedData?.status);
      setDialogOpen(false);
    }
  };

  const handleStatusChange = (event) => {
    let selectedValue = event.target.value;
    handleOpenDialog();
    setSelectedStatus(selectedValue);

    if (
      selectedData &&
      loggedInData?._id == selectedData?.raisedBy?.[0]?._id &&
      loggedInData?.roles.some((item) => item === "Approver")
    ) {
      setIsSameApprover(true);
    } else {
      setIsSameApprover(false);
    }
  };

  useEffect(() => {
    if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.some((item) => "Viewer" === item)
    ) {
      setIsViewer(true);
    } else if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.length === 1
    ) {
      setIsOnlyUser(true);
    } else if (loggedInData?.roles.some((item) => "Approver" === item)) {
      setIsApprover(true);
    } else if (loggedInData?.roles.some((item) => "Authorizer" === item)) {
      setIsAuthorizer(true);
    }
    getPaymentHistory();
  }, []);

  useEffect(() => {
    if (selectedData == undefined) {
      history.push("/app/internalForms");
    }
  }, [vendorData]);

  const updateSupplierBuyerState = (data) => {
    dispatch({
      type: "SET_SELECTED_VENDOR_PAYMENT",
      payload: data,
    });
  };

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          toast.success(response.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while downloading the file.");
      });
  };

  async function getPaymentHistory() {
    try {
      let updatedFormData = {
        paymentStatusId: selectedData?._id,
      };

      try {
        const response = await getPaymentHistoryStatus(updatedFormData);
        if (response.status === "200") {
          setStatusHistoy(response?.data[0]);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        setStatusHistoy([]);
      }
    } catch (error) {
      toast.error("Error in get payment history");
    }
  }

  async function takeActionOnPaymentRequest(
    formData,
    newStatus,
    rejectionReason,
    approverComments,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid
  ) {
    setIsLoading(true);

    try {
      let updatedFormData = {
        ...formData,
        status: newStatus,
        raisedBy: formData?.raisedBy[0]?._id,
        requestType: "vendorPayment",
        attachments: uploadedFiles,
      };

      if (newStatus === PAYMENT_STATUS.REJECTED && rejectionReason !== "") {
        updatedFormData.reasonForRejection = rejectionReason;
      } else if (newStatus === PAYMENT_STATUS.APPROVED) {
        updatedFormData.approverComments = approverComments;
      } else if (newStatus === PAYMENT_STATUS.PAYMENT_COMPLETED) {
        updatedFormData.transactionID = transactionID;
        updatedFormData.adminComments = adminComments;
      } else if (newStatus === PAYMENT_STATUS.NEW_PARTIAL_PAYMENT) {
        updatedFormData.transactionID = transactionID;
        updatedFormData.adminComments = adminComments;
        updatedFormData.amountPaid = amountPaid;
        updatedFormData.status = PAYMENT_STATUS.PARTIAL_PAYMENT;
      }

      const response = await actionOnPaymentRequest(updatedFormData);
      if (response.status === "200") {
        toast.success("Successfully updated!");
        getPaymentHistory();
        setIsLoading(false);
        setSelectedStatus(selectedStatus);
        updateSupplierBuyerState(response?.data?.response);
        setDialogOpen(false);
      } else if (response.status == "400") {
        toast.error(response?.message || "Something went wrong!");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setIsLoading(false);
      setDialogOpen(false);
    }
  }

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Vendor Payments"), link: "/#/app/internalForms" },
    { label: t("Edit"), link: "" },
  ];
  return (
    <>
      <Toaster />
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <HeaderComp
            selectedStatus={selectedStatus}
            selectedData={selectedData}
            backUrlPayload="vendorPaymentComp"
          />
        </div>

        {/*  */}
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Paper sx={{ height: "100%" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Date")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {moment(selectedData?.date).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Raised by")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.raisedBy[0]?.username ||
                        selectedData?.raisedBy[0]?.userName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Payment from")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.paymentFrom}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <RenderAttachments
                attachments={selectedData?.attachments}
                handleDownload={handleDownload}
              />{" "}
              <Box sx={{ marginLeft: "16px", padding: "10px 0" }}>
                {selectedData?.remarks && (
                  <Box mb={2}>
                    <Typography fontWeight="600" fontSize="14px">
                      <b>{t("Remarks")}:</b>
                    </Typography>
                    {selectedData?.remarks}
                  </Box>
                )}
                {selectedData?.reasonForRejection && (
                  <>
                    <Typography
                      sx={{ color: "#FF5C93" }}
                      fontWeight="600"
                      fontSize="14px"
                    >
                      {t("Rejected reason")}:
                    </Typography>
                    {selectedData?.reasonForRejection}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={7}>
            <Paper>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>{t("Total amount")}:</b>
                    </TableCell>
                    <TableCell>
                      <b> {formatIndianCurrency(selectedData?.amount)} </b>
                    </TableCell>
                  </TableRow>
                  {selectedData?.status === PAYMENT_STATUS.PARTIAL_PAYMENT && (
                    <TableRow>
                      <TableCell>
                        <b>{t("Paid")}:</b>
                      </TableCell>
                      <TableCell>
                        <b>{formatIndianCurrency(selectedData?.amountPaid)}</b>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <b>{t("Amount due")}:</b>
                    </TableCell>
                    <TableCell>
                      {selectedData?.status === PAYMENT_STATUS.APPROVED ||
                      selectedData?.status === PAYMENT_STATUS.PENDING ? (
                        <b>{formatIndianCurrency(selectedData?.amount || 0)}</b>
                      ) : (
                        <b>
                          {formatIndianCurrency(selectedData?.amountDue || 0)}
                        </b>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ border: "none" }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <b>{t("Account details")}</b>
                      </Typography>
                      <Typography variant="body2">
                        Name: {selectedData?.paymentDetails?.accountHolderName}
                        <br />
                        Bank: {selectedData?.paymentDetails?.bankName}
                        <br />
                        Account no:{" "}
                        {selectedData?.paymentDetails?.accountNumber}
                        <br />
                        IFSC: {selectedData?.paymentDetails?.ifscCode}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ border: "none" }}>
                      <Box>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>{t("Status")}</b>
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            disabled={
                              isViewer ||
                              isOnlyUser ||
                              selectedStatus ===
                                PAYMENT_STATUS.PAYMENT_COMPLETED
                            }
                          >
                            {vendorPaymentStatus.map((option) => (
                              <MenuItem
                                key={option}
                                value={option}
                                disabled={
                                  option === PAYMENT_STATUS.PENDING ||
                                  option === PAYMENT_STATUS.PARTIAL_PAYMENT ||
                                  (option ===
                                    PAYMENT_STATUS.PAYMENT_COMPLETED &&
                                    isApprover) ||
                                  (option === PAYMENT_STATUS.PARTIAL_PAYMENT &&
                                    isApprover) ||
                                  (option ===
                                    PAYMENT_STATUS.NEW_PARTIAL_PAYMENT &&
                                    isApprover)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        {/*  */}
        <div className={classes.statusDiv}>
          <StatusHistory statusData={statusHistory} editable={true} />
        </div>
      </div>
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        inputStatus={selectedStatus}
        title="Changing status to "
        textFieldLabel="Reason"
        isLoading={isLoading}
        imageFileName="VendorPaymentDocs"
      />
    </>
  );
};

export default EditVendorRequest;
