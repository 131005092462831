import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
} from "@mui/material";
import { getWebProfileDownloaders } from "./webLeadsState";
import { formatDateAndTime } from "../inquiries/helper/utils";

const Webleads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Website Leads", link: "" },
  ];

  useEffect(() => {
    const getLeads = async () => {
      try {
        const data = await getWebProfileDownloaders();
        console.log(data);

        setLeads(data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch leads");
        setLoading(false);
      }
    };

    getLeads();
  }, []);

  return (
    <Box mt={10} bgcolor={"white"} p={3} height={"100%"}>
      <BreadCrumbs data={breadcrumb} />

      <Typography variant="h4" component="h1" gutterBottom>
        Website Leads
      </Typography>
      <Typography my={1}>(Companies who downloaded Binbag Profile)</Typography>

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="website leads table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Email Sent</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {leads.map((lead) => (
                <TableRow key={lead.id}>
                  <TableCell>{formatDateAndTime(lead.createdAt)}</TableCell>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>{lead.organization}</TableCell>
                  <TableCell>{lead.source || "Binbag Website"}</TableCell>
                  <TableCell>
                    <Chip
                      label={lead.emailSent ? "Sent" : "Not Sent"}
                      color={lead.emailSent ? "success" : "error"}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Webleads;
