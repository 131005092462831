import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  FormHelperText,
  Typography,
  Paper,
  Divider,
  Alert,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumberFormatCustom } from "../../../utils/currencyFormat";
import { useSelector } from "react-redux";
import UploadFilesImages from "../components/UploadFilesImages";
import {
  getMiscellaneousRequestOptions,
  createOtherRequest,
} from "../InternalFormState";
import OptionsDialog from "../components/OptionsDialog";
import SendIcon from "@mui/icons-material/Send";
import toast from "react-hot-toast";

export default function MiscellaneousRequestForm({ toggleView }) {
  const [otherReasonOptions, setOtherReasonOptions] = useState([]);
  const [paymentFromOptions, setPaymentFromOptions] = useState([]);
  const [selectedPaymentFrom, setSelectedPaymentFrom] = useState([]);
  const [selectedOtherReason, setSelectedOtherReason] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [amount, setAmount] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [loader, setLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const data = useSelector((state) => state.userdata.getData);

  const [bankDetailsError, setBankDetailsError] = useState(null);

  const [isUserAndViewer, setIsUserAndViewer] = useState(false);

  useEffect(() => {
    if (data) {
      const { roles } = data;
      const isUser = roles.includes("user");
      const isViewer = roles.includes("Viewer");
      const isApprover = roles.includes("Approver");

      if ((isUser || isViewer) && !isApprover) {
        setIsUserAndViewer(true);
      }
    }
  }, [data]);

  const [optionsChanged, setOptionsChanged] = useState("");

  const handleOptionsChangedParent = (value) => {
    setOptionsChanged(value);
  };

  const handleAccountHolderNameChange = (e) => {
    const inputValue = e.target.value;

    const isValidInput = /^[A-Za-z\s]+$/.test(inputValue);

    if (isValidInput || inputValue === "") {
      setAccountHolderName(inputValue);
      setBankDetailsError(null);
    }
  };
  const handleBankNameChange = (e) => {
    const inputValue = e.target.value;

    const isValidInput = /^[A-Za-z\s]+$/.test(inputValue);

    if (isValidInput || inputValue === "") {
      setBankName(inputValue);
      setBankDetailsError(null);
    }
  };
  const handleAccountNumberChange = (e) => {
    const inputValue = e.target.value;

    const isValidInput = /^[0-9]+$/.test(inputValue);

    if (isValidInput || inputValue === "") {
      setAccountNumber(inputValue);
      setBankDetailsError(null);
    }
  };

  const handleIfscCodeChange = (e) => {
    const inputValue = e.target.value;
    setIfscCode(inputValue);
    setBankDetailsError(null);
  };

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });
    setUploadedFiles(filesWithoutRef);
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (
      (accountHolderName.trim() !== "" ||
        bankName.trim() !== "" ||
        accountNumber.trim() !== "" ||
        ifscCode.trim() !== "") &&
      (accountHolderName.trim() === "" ||
        bankName.trim() === "" ||
        accountNumber.trim() === "" ||
        ifscCode.trim() === "")
    ) {
      let requiredFields = [];
      if (accountHolderName.trim() === "") requiredFields.push("accountName");
      if (bankName.trim() === "") requiredFields.push("bankName");
      if (accountNumber.trim() === "") requiredFields.push("accountNumber");
      if (ifscCode.trim() === "") requiredFields.push("ifscCode");

      setBankDetailsError(requiredFields);
      setLoader(false);
      return;
    }
    setLoader(true);
    const formData = {
      date: new Date().toISOString(),
      amount: amount,
      otherOption: selectedOtherReason,
      paymentFrom: selectedPaymentFrom,
      paymentDetails: {
        accountHolderName: accountHolderName,
        bankName: bankName,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
      },
      raisedBy: data._id,
      remarks: remarks,
      attachments: uploadedFiles,
    };
    createOtherRequest(formData)
      .then(() => {
        toast.success("Successfully submitted!");
        setTimeout(() => {
          toggleView(false);
          setLoader(false);
        }, 2000);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  async function fetchOtherRequestOptions() {
    try {
      const allOptions = await getMiscellaneousRequestOptions();
      const extractedData = allOptions.data
        .filter((item) => item.isActive)
        .map((item) => ({
          _id: item._id,
          option: item.option,
        }));
      setOtherReasonOptions(extractedData);
    } catch (error) {
      toast.error("Error fetching request type");
    }
  }

  useEffect(() => {
    fetchOtherRequestOptions();
    const fetchPaymentFrom = async () => {
      const apiResponse = ["Binbag Recyling", "Binbag Environmental", "UGT"];
      setPaymentFromOptions(apiResponse);
    };

    fetchPaymentFrom();
  }, [optionsChanged]);

  const isAdmin = data && data.roles.some((item) => "Admin" === item);
  const isApprover = data && data.roles.some((item) => "Approver" === item);
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          maxWidth: 800,
          mx: "auto",
          p: { xs: 1, md: 4 },
        }}
      >
        <Typography variant="h4" gutterBottom>
          Miscellaneous Form
        </Typography>
        <Alert severity="info" sx={{ mb: 3 }}>
          Note: If this is an advance payment request, you'll need to submit
          expenses detailing how you utilized the funds after the advance is
          disbursed.
        </Alert>
        <Divider sx={{ mb: 3 }} />

        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={setDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disabled
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Amount"
                fullWidth
                required
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Payment From</InputLabel>
                <Select
                  value={selectedPaymentFrom}
                  onChange={(e) => setSelectedPaymentFrom(e.target.value)}
                  label="Payment From"
                >
                  {paymentFromOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={isAdmin || isApprover ? 8 : 12}>
                  <FormControl fullWidth required>
                    <InputLabel>Request Type</InputLabel>
                    <Select
                      value={selectedOtherReason}
                      onChange={(e) => setSelectedOtherReason(e.target.value)}
                      label="Request Type"
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                      {otherReasonOptions.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.option}
                        </MenuItem>
                      ))}
                    </Select>
                    {isUserAndViewer && (
                      <FormHelperText>
                        If the reason you are looking for is not listed here,
                        please inform @Trisha
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {(isAdmin || isApprover) && (
                  <Grid item xs={2}>
                    <OptionsDialog
                      onOptionsChanged={handleOptionsChangedParent}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Bank Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Account Holder Name"
                fullWidth
                value={accountHolderName}
                onChange={handleAccountHolderNameChange}
                error={
                  bankDetailsError && bankDetailsError.includes("accountName")
                }
                helperText={
                  bankDetailsError && bankDetailsError.includes("accountName")
                    ? "Required field"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Bank Name"
                fullWidth
                value={bankName}
                onChange={handleBankNameChange}
                error={
                  bankDetailsError && bankDetailsError.includes("bankName")
                }
                helperText={
                  bankDetailsError && bankDetailsError.includes("bankName")
                    ? "Required field"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Account No"
                fullWidth
                value={accountNumber}
                onChange={handleAccountNumberChange}
                error={
                  bankDetailsError && bankDetailsError.includes("accountNumber")
                }
                helperText={
                  bankDetailsError && bankDetailsError.includes("accountNumber")
                    ? "Required field"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="IFSC Code"
                fullWidth
                value={ifscCode}
                onChange={handleIfscCodeChange}
                error={
                  bankDetailsError && bankDetailsError.includes("ifscCode")
                }
                helperText={
                  bankDetailsError && bankDetailsError.includes("ifscCode")
                    ? "Required field"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <UploadFilesImages
                fileUploadData={handleUploadedFiles}
                setIsUploading={setUploadingFile}
                fileUploadType={"miscellaneousReqDocs"}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Remarks"
                fullWidth
                required
                multiline
                rows={4}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <Button
                variant="contained"
                type="submit"
                disabled={uploadingFile || loader}
                startIcon={
                  loader ? <CircularProgress size={20} /> : <SendIcon />
                }
                size="large"
              >
                {loader ? "Submitting..." : "Submit Request"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
