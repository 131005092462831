import React, { useState } from "react";
import { Button, Grid, Paper, CircularProgress } from "@mui/material";
import classnames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import BasicDetails from "./pages/BasicDetails";
import { addCustomer } from "./CustomerState";

export default function AddCustomer(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);

  const methods = useForm({
    defaultValues: {
      companyName: "",
      contactName: "",
      email: "",
      mobile: "",
      pan_no: "",
      taxNo: "",
      abbreviation: "",
      notificationAllowed: "",
      countryCode: "+91",
      companySize: "",
      createdAt: new Date().toISOString(),
      isActive: true,
      isprimary: true,
      isTemperory: true,
      createdBy: "System",
    },
  });

  const breadcrumb = [
    { label: t("common_customers"), link: "/#/app/customers" },
    { label: t("New Customer"), link: "" },
  ];

  const handleSubmit = (data) => {
    setShowLoader(true);
    addCustomer(data).then((response) => {
      if (response.status === "200") {
        toast.success(response.message);
        setTimeout(() => props.history.push("/app/customers"), 2000);
      } else if (response.status === "401") {
        toast.error(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else if (response.status === "500") {
        toast.error(response.message);
      } else {
        handleErrorResponse(response);
      }
      setShowLoader(false);
    });
  };

  const handleErrorResponse = (response) => {
    if (response.ErrorData) {
      const msg = response.ErrorData.details
        .map((item) => item.message)
        .join(", ");

      toast.error(msg);
    } else {
      toast.error("Something went Wrong! Please try again!");
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.form}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={1} xs={0} sm={0} />
          <Grid item lg={6} md={11} xs={12} sm={12}>
            <Paper className={classes.paperButton}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={2} sm={1} xs={1} />
                    <Grid
                      item
                      lg={8}
                      md={10}
                      sm={10}
                      xs={10}
                    >
                      <BasicDetails />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {showLoader ? (
                          <CircularProgress size={28} />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {t("Save")}
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Paper>
          </Grid>
          <Grid item lg={3} md={1} xs={0} sm={0} />
        </Grid>
      </div>
    </>
  );
}
