import React, { useState } from "react";
import CalendarLayout from "../../../components/Calander/CalanderLayout";
import EventDialog from "./EventDialog";

const YourTimeTable = () => {
  const [myEvents, setMyEvents] = useState([
    {
      id: 1,
      title: "MPR of TCS",
      start: new Date(2024, 9, 10, 10, 0),
      end: new Date(2024, 9, 10, 12, 0),
      color: "#3174ad", // Add color property
    },
    {
      id: 2,
      title: "Inspection Mphasis",
      start: new Date(2024, 9, 12, 14, 0),
      end: new Date(2024, 9, 12, 16, 0),
      color: "#32a852", // Add color property
    },
    {
      id: 3,
      title: "Tender Submission Deadline",
      start: new Date(2024, 9, 15, 9, 0),
      end: new Date(2024, 9, 15, 18, 0),
      color: "#32a852", // Add color prope
    },
    {
      id: 4,
      title: "Client Meeting",
      start: new Date(2024, 9, 18, 11, 0),
      end: new Date(2024, 9, 18, 12, 30),
      color: "#32a852", // Add color prope
    },
    {
      id: 5,
      title: "Team Training Session",
      start: new Date(2024, 9, 20, 13, 0),
      end: new Date(2024, 9, 20, 17, 0),
      color: "#32a852", // Add color prope
    },
    {
      id: 7,
      title: "Quarterly Review",
      start: new Date(2024, 9, 28, 9, 0),
      end: new Date(2024, 9, 28, 11, 0),
      color: "#32a852", // Add color prope
    },
  ]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setDialogOpen(true);
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEvent(null);
    setSelectedSlot(null);
  };

  const handleSaveEvent = (eventData) => {
    if (selectedEvent) {
      setMyEvents(
        myEvents.map((event) =>
          event.id === selectedEvent.id ? { ...event, ...eventData } : event
        )
      );
    } else {
      setMyEvents([...myEvents, { id: Date.now(), ...eventData }]);
    }
    handleCloseDialog();
  };

  const handleEventDrop = ({ event, start, end }) => {
    setMyEvents(
      myEvents.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      )
    );
  };

  const handleEventResize = ({ event, start, end }) => {
    setMyEvents(
      myEvents.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      )
    );
  };

  return (
    <>
      <CalendarLayout
        events={myEvents}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
      />
      <EventDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveEvent}
        event={selectedEvent}
        slotInfo={selectedSlot}
      />
    </>
  );
};

export default YourTimeTable;
