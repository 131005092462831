import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Link,
} from "@mui/material";

const ConvertToMpr = ({ open, onClose, data }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Convert to MPR</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            To link this inquiry to an MPR (Material Pickup Request), follow
            these steps:
          </Typography>
          <ol>
            <li>
              Go to the
              <Link href="http://localhost:4001/#/app/customers" px={1}>
                Customers tab
              </Link>
              and search for the customer name. If it doesn't exist, add the
              customer.
            </li>
            <li>Add this customer's branch.</li>
            <li>
              When you see the option to add MPR, copy the ID of this inquiry
              before adding the MPR. This will link the inquiry to the MPR.
            </li>
            <li>Fill in the required information to create the MPR.</li>
          </ol>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConvertToMpr;
