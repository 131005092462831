import { apiUrl } from "../../data/config";

export function createPaymentRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/paymentRequest";

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}
export function createReimbursement(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/reimbursementRequest";

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}

export function createOtherRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/miscelleneousPayment";

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}

export function createLeaveRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/leaveRequest";

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}

export function getAllPaymentRequests(data) {
  let url =
    apiUrl +
    `/v1/tenant/getAllPaymentRequests?page=${data.page}&limit=${data.limit}&searchTerm=${data.searchTerm}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllVendorPaymentRequests(data) {
  let url =
    apiUrl +
    `/v1/tenant/getAllVendorPaymentRequests?page=${data.page}&limit=${data.limit}&searchTerm=${data.searchTerm}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getReimbursements(data) {
  let url =
    apiUrl +
    `/v1/tenant/getReimbursementRequests?page=${data.page}&limit=${data.limit}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllMiscellaneousRequests(data) {
  let url =
    apiUrl +
    `/v1/tenant/miscelleneousPayment?page=${data.page}&limit=${data.limit}&searchTerm=${data.searchTerm}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllLeaveRequests(data) {
  let url =
    apiUrl +
    `/v1/tenant/getAllLeaveRequests?page=${data.page}&limit=${data.limit}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPaymentHistoryStatus(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/paymentStatus";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDashboardData(data) {
  let url = apiUrl + "/v1/tenant/dashboard";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getEmployeeDashboardData(data) {
  let url = apiUrl + "/v1/tenant/employeeDashboard";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function actionOnPaymentRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/actionOnPaymentRequest";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}

export function actionOnMiscellaneousRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/actionOnMiscelleneousPayment";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function actionOnReimbursement(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/actionOnReimbursement";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}

export function actionOnLeaveRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/actionOnLeaveRequest";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}

export function searchPaymentBankDetails(searchData, dispatch) {
  let url = apiUrl + "/v1/tenant/searchPaymentBankDetails";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(searchData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      // console.error('Error creating reimbursement:', error);
      throw error;
    });
}

export function getFile(data) {
  // data = data1;
  let url = apiUrl + "/v1/filegetAWS";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function addMiscellaneousRequestOption(formData, dispatch) {
  const url = `${apiUrl}/v1/addOtherRequestOption`;
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getMiscellaneousRequestOptions() {
  const url = `${apiUrl}/v1/getAllOtherRequestOption`;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return {
        status: "500",
        message: "Something went wrong",
      };
    }
  });
}

export function updateMiscellaneousReqOption(formData, dispatch) {
  const url = `${apiUrl}/v1/updateOtherRequestOption`;
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

// http://localhost:5001/v1/getAllAdvancePaymentStatus

export function getAdvancePaymentStatus() {
  const url = `${apiUrl}/v1/getAllAdvancePaymentStatus`;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return {
        status: "500",
        message: "Something went wrong",
      };
    }
  });
}

export function updatePaymentStatus(formData) {
  const url = `${apiUrl}/v1/tenant/updatePaymentStatus`;
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}
