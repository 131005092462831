import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getData } from "../LoginContainer/LoginState";
import { getCertificateData } from "../setting/SettingState";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Snackbar,
  Badge,
} from "@mui/material";
import {
  Search,
  Assessment,
  People,
  ShoppingCart,
  Description,
} from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WebIcon from "@mui/icons-material/Web";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  cardWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  badge: {
    position: "absolute",
    top: theme.spacing(0.1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  customBadge: {
    "& .MuiBadge-badge": {
      minWidth: "30px", // Increase this value to make the badge wider
      padding: "0 6px", // Adjust padding as needed
      height: "22px", // Adjust height if necessary
      borderRadius: "11px", // Half of the height for rounded corners
      fontSize: "0.6rem", // Adjust font size if needed
      fontWeight: "bold",
      whiteSpace: "nowrap", // Prevents text from wrapping
    },
  },
  card: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s, box-shadow 0.3s",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    "&:hover": {
      boxShadow: theme.shadows[4],
    },
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    fontSize: 40,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  searchBar: {
    marginBottom: theme.spacing(3),
    width: "100%",
    maxWidth: 500,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const DashboardItem = ({ title, link, icon: Icon, tabs, badge }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(link, { tabs });
  };

  return (
    <div className={classes.cardWrapper}>
      {badge && (
        <div className={classes.badge}>
          <Badge
            badgeContent={badge}
            color="primary"
            className={classes.customBadge}
            max={999} // Increase this value if you expect larger numbers
          />
        </div>
      )}
      <Card
        className={classes.card}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <CardContent className={classes.cardContent}>
          <Icon className={classes.icon} />
          <Typography variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default function Dashboard() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const data = useSelector((state) => state.userdata);
  const userType = data.getData
    ? data.getData.type
    : localStorage.getItem("type");
  const custid = data.getData
    ? data.getData.customerid
    : localStorage.getItem("customerid");

  const getUserType = (userData) => {
    if (userData?.type === "tenant") {
      return userData.roles?.includes("Admin") ? "tenantAdmin" : "tenantUser";
    }
    return userData?.type === "customer" ? "customer" : "customer";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getData(dispatch);
        if (userType === "tenant" && !data.getData?.roles?.includes("user")) {
          const bodyData = { tenID: custid };
          const resp = await getCertificateData(bodyData, dispatch);
          if (resp.status === "200" && resp.tenantData) {
            if (!resp.tenantData.isVerified) {
              setError("Please add Pollution Certificate");
            }
            if (!resp.tenantData.isValid) {
              setError(
                "Your Pollution Certificate is Expired! Please contact Admin!"
              );
            }
          }
        }
      } catch (err) {
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const dashboardItems = {
    customer: [
      {
        title: t("common_material_pickup"),
        link: "/app/MPR",
        icon: ShoppingCart,
      },
      {
        title: t("common_admin"),
        link: "/app/admin",
        icon: Assessment,
      },
    ],
    tenantAdmin: [
      {
        title: t("Events"),
        link: "/app/events",
        icon: CalendarMonthIcon,
        badge: "Coming Soon",
      },
      {
        title: t("Leads"),
        link: "/app/leads",
        icon: Search,
      },
      {
        title: t("Inquiries"),
        link: "/app/inquiries",
        icon: Description,
      },
      {
        title: t("Customers"),
        link: "/app/customers",
        icon: People,
      },
      {
        title: t("Buyers"),
        link: "/app/mpUsers",
        icon: SensorOccupiedIcon,
      },
      {
        title: t("Request Forms"),
        link: "/app/internalForms",
        icon: Description,
      },
      {
        title: t("common_marketplace"),
        link: "/app/marketplace",
        icon: ShoppingCart,
      },
      {
        title: t("common_admin"),
        link: "/app/admin",
        icon: AdminPanelSettingsIcon,
      },
      {
        title: t("Website Leads"),
        link: "/app/webLeads",
        icon: WebIcon,
      },
    ],
    tenantUser: [
      {
        title: t("Events"),
        link: "/app/events",
        icon: CalendarMonthIcon,
        badge: "Coming Soon",
      },
      {
        title: t("Leads"),
        link: "/app/leads",
        icon: Search,
      },
      {
        title: t("Inquiries"),
        link: "/app/inquiries",
        icon: Description,
      },
      {
        title: t("Customers"),
        link: "/app/customers",
        icon: People,
      },
      {
        title: t("Request Forms"),
        link: "/app/internalForms",
        icon: Description,
      },
      {
        title: t("common_marketplace"),
        link: "/app/marketplace",
        icon: ShoppingCart,
      },
    ],
  };

  const filteredItems =
    dashboardItems[getUserType(data.getData)]?.filter((item) => {
      const itemMatchesSearch = item.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      return itemMatchesSearch;
    }) || [];

  return (
    <div className={classes.root} style={{ marginTop: "70px" }}>
      <Grid container spacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
        {filteredItems.map((item, index) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
            <DashboardItem {...item} badge={item.badge} />
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
}
