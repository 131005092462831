import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Typography,
  Paper,
  Divider,
  Alert,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumberFormatCustom } from "../../../utils/currencyFormat";
import { createReimbursement } from "../InternalFormState";
import { useSelector } from "react-redux";
import UploadFilesImages from "../components/UploadFilesImages";
import SendIcon from "@mui/icons-material/Send";
import toast from "react-hot-toast";

export default function ReimbursementForm({ toggleView }) {
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [paymentFromOptions, setPaymentFromOptions] = useState([]);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [selectedPaymentFrom, setSelectedPaymentFrom] = useState([]);
  const [clientDetails, setClientDetails] = useState("");
  const [advanceReason, setAdvanceReason] = useState("");
  const [remarks, setRemarks] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [amount, setAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const data = useSelector((state) => state.userdata.getData);

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });
    setUploadedFiles(filesWithoutRef);
  };

  const submitForm = (event) => {
    event.preventDefault();
    const typesArray = selectedPaymentTypes.map((type) => type);
    setLoader(true);
    const formData = {
      date: new Date().toISOString(),
      amount: amount,
      paymentTypes: [
        {
          types: typesArray,
          clientDetails: clientDetails,
          advanceReason: advanceReason,
        },
      ],
      paymentFrom: selectedPaymentFrom,
      raisedBy: data._id,
      remarks: remarks,
      attachments: uploadedFiles,
    };
    createReimbursement(formData)
      .then(() => {
        toast.success("Successfully submitted!");
        setTimeout(() => {
          toggleView(false);
          setLoader(false);
        }, 2000);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    const fetchPaymentTypes = async () => {
      const apiResponse = [
        "Out Of Pocket Expense",
        "Material Transportation",
        "Advance",
      ];
      setPaymentTypeOptions(apiResponse);
    };

    const fetchPaymentFrom = async () => {
      const apiResponse = ["Binbag Recyling", "Binbag Environmental", "UGT"];
      setPaymentFromOptions(apiResponse);
    };

    fetchPaymentTypes();
    fetchPaymentFrom();
  }, []);

  return (
    <>
      <Paper
        elevation={3}
        sx={{ p: { xs: 1, md: 4 }, maxWidth: 800, mx: "auto" }}
      >
        <Typography variant="h4" gutterBottom>
          Reimbursement Form
        </Typography>
        <Alert severity="info" sx={{ mb: 3 }}>
          Note: All out-of-pocket expenses from 01/04/2023 will have to be
          recorded here and routed through Accounts department.
        </Alert>

        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={setDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disabled
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Amount"
                fullWidth
                required
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Payment From</InputLabel>
                <Select
                  value={selectedPaymentFrom}
                  onChange={(e) => setSelectedPaymentFrom(e.target.value)}
                  label="Payment From"
                >
                  {paymentFromOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <UploadFilesImages
                fileUploadData={handleUploadedFiles}
                setIsUploading={setUploadingFile}
                fileUploadType={"ReimbursementDocs"}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Remarks"
                fullWidth
                required
                multiline
                rows={4}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <Button
                variant="contained"
                type="submit"
                disabled={uploadingFile || loader}
                startIcon={
                  loader ? <CircularProgress size={20} /> : <SendIcon />
                }
                size="large"
              >
                {loader ? "Submitting..." : "Submit Request"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
