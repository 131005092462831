import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  FormControl,
  Typography,
  Paper,
  Autocomplete,
  Divider,
  Alert,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  createPaymentRequest,
  searchPaymentBankDetails,
} from "../../InternalFormState";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import SendIcon from "@mui/icons-material/Send";
import { NumberFormatCustom } from "../../../../utils/currencyFormat";
import UploadFilesImages from "../../components/UploadFilesImages";
import toast from "react-hot-toast";

export default function VendorRequestForm({ toggleView }) {
  const [paymentFromOptions, setPaymentFromOptions] = useState([]);
  const [selectedPaymentFrom, setSelectedPaymentFrom] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [amount, setAmount] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [loader, setLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    message: "",
  });
  const data = useSelector((state) => state.userdata.getData);

  const [searchResults, setSearchResults] = useState([]);

  let debounceTimer;

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });
    setUploadedFiles(filesWithoutRef);
  };

  const nowSearchPaymentBankDetails = async (searchData) => {
    try {
      let response = await searchPaymentBankDetails(searchData);
      return response;
    } catch (error) {
      toast.error("Retry search is not working");
    }
  };

  const handleSearchQueryChange = async (e) => {
    const inputValue = e.target.value;

    const isLengthValid = inputValue?.length >= 3;

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(async () => {
      if (inputValue.trim() === "" || !isLengthValid) {
        setSearchResults([]);
      } else {
        const searchData = {
          search: inputValue,
          searchType: "vendorPayment",
        };
        try {
          const response = await nowSearchPaymentBankDetails(searchData);
          if (response.status == "204") {
            toast.error("No Vendor found, try inserting manually");
          } else if (response.status == "200") {
            setSearchResults(response?.data);
          }
        } catch (error) {
          console.error("Error", error);
        }
      }
    }, 300);
  };

  const handleSelectResult = (result) => {
    setAccountHolderName(result.accountHolderName);
    setAccountNumber(result.accountNumber);
    setIfscCode(result.ifscCode);
    setBankName(result.bankName);
    setSearchResults([]);
  };

  const handleAccountHolderNameChange = (e) => {
    const inputValue = e.target.value;

    const isValidInput = /^[A-Za-z\s]+$/.test(inputValue);

    if (isValidInput || inputValue === "") {
      setAccountHolderName(inputValue);
    }
  };
  const handleBankNameChange = (e) => {
    const inputValue = e.target.value;

    const isValidInput = /^[A-Za-z\s]+$/.test(inputValue);

    if (isValidInput || inputValue === "") {
      setBankName(inputValue);
    }
  };
  const handleAccountNumberChange = (e) => {
    const inputValue = e.target.value;

    const isValidInput = /^[0-9]+$/.test(inputValue);

    if (isValidInput || inputValue === "") {
      setAccountNumber(inputValue);
    }
  };

  //option to select or create new bank details object

  const submitForm = (event) => {
    event.preventDefault();
    setLoader(true);
    const formData = {
      date: new Date().toISOString(),
      amount: amount,
      paymentFrom: selectedPaymentFrom,
      paymentDetails: {
        accountHolderName: accountHolderName,
        bankName: bankName,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
      },
      raisedBy: data._id,
      remarks: remarks,
      attachments: uploadedFiles,
      requestType: "vendorPayment",
    };
    createPaymentRequest(formData)
      .then(() => {
        toast.success("Successfully submitted!");
        setTimeout(() => {
          toggleView(false);
          setLoader(false);
        }, 2000);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    const fetchPaymentFrom = async () => {
      const apiResponse = ["Binbag Recyling", "Binbag Environmental", "UGT"];
      setPaymentFromOptions(apiResponse);
    };
    fetchPaymentFrom();
  }, []);

  return (
    <>
      <Paper
        elevation={3}
        sx={{ p: { xs: 1, md: 4 }, maxWidth: 800, mx: "auto" }}
      >
        <Typography variant="h4" gutterBottom>
          Vendor Payments Form
        </Typography>
        <Alert severity="info" sx={{ mb: 3 }}>
          Note: All payments to vendors from 19/02/2024 will have to be recorded
          here and routed through Accounts department.
        </Alert>

        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={setDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disabled
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Amount"
                fullWidth
                required
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Payment From</InputLabel>
                <Select
                  value={selectedPaymentFrom}
                  onChange={(e) => setSelectedPaymentFrom(e.target.value)}
                  label="Payment From"
                >
                  {paymentFromOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Bank Details
              </Typography>
              <Autocomplete
                freeSolo
                options={searchResults}
                getOptionLabel={(option) => option.accountHolderName || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search by Vendor Name"
                    fullWidth
                    onChange={handleSearchQueryChange}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) handleSelectResult(newValue);
                }}
              />
            </Grid>

            {showMessage.show && (
              <Grid item xs={12}>
                <Typography color="textSecondary">
                  {showMessage.message}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                label="Account Holder Name"
                fullWidth
                required
                value={accountHolderName}
                onChange={handleAccountHolderNameChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Bank Name"
                fullWidth
                required
                value={bankName}
                onChange={handleBankNameChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Account No"
                fullWidth
                required
                value={accountNumber}
                onChange={handleAccountNumberChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="IFSC Code"
                fullWidth
                required
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Upload Documents
              </Typography>
              <UploadFilesImages
                fileUploadData={handleUploadedFiles}
                setIsUploading={setUploadingFile}
                fileUploadType={"VendorPaymentDocs"}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Remarks"
                fullWidth
                multiline
                rows={4}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <Button
                variant="contained"
                type="submit"
                disabled={uploadingFile || loader}
                startIcon={
                  loader ? <CircularProgress size={20} /> : <SendIcon />
                }
                size="large"
              >
                {loader ? "Submitting..." : "Submit Request"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}
