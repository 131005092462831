import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useDebounce } from "use-debounce";
import toast from "react-hot-toast";
import { formatIndianCurrency } from "../../../../utils/currencyFormat";
import Table from "../../../../components/Table/Table";
import useStyles from "../../styles";
import PaymentRequestForm from "./PaymentRequestForm";
import { getAllPaymentRequests } from "../../InternalFormState";
import {
  paymentTableHeader,
  TabPanel,
  a11yProps,
  getStatusStyle,
} from "../../components/config/tableHelper";
import { tableOptions } from "../../components/config/tableConfig";
import SectionHeader from "../../components/SectionHeader";

export default function TabPannelPayment() {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const data = useSelector((state) => state.userdata.getData);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const dispatch = useDispatch();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300); // 300ms delay

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
    searchTerm: "",
  });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);

  useEffect(() => {
    const maxRowsPerPage = totalPages || 10;
    const dynamicRowsPerPageOptions = [10, 20, 50].filter(
      (option) => option <= maxRowsPerPage
    );
    if (!dynamicRowsPerPageOptions.includes(maxRowsPerPage)) {
      dynamicRowsPerPageOptions.push(maxRowsPerPage);
    }

    setRowsPerPageOptions(dynamicRowsPerPageOptions);
  }, [totalPages]);

  let tabData = [];
  let myRequests = [];
  let allRequests = [];
  let pendingRequests = [];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (
    data &&
    data.roles.some((item) => "user" === item) &&
    !data.roles.some((item) => "Viewer" === item) &&
    !data.roles.some((item) => "Approver" === item)
  ) {
    tabData = [{ label: "My Requests", index: 0, name: "", data: myRequests }];
  } else if (
    (data && data?.roles.some((item) => "Viewer" === item)) ||
    data?.roles.some((item) => "Approver" === item) ||
    data?.roles.some((item) => "Admin" === item)
  ) {
    tabData = [
      { label: "All", index: 0, name: "", data: allRequests },
      { label: "Pending", index: 1, name: "", data: pendingRequests },
    ];
  }

  async function fetchPaymentRequests(data) {
    setFetchLoader(true);
    try {
      const allPaymentRequests = await getAllPaymentRequests(data);

      if (allPaymentRequests.status == "200") {
        setPaymentRequests(allPaymentRequests.data);
        setTotalPages(allPaymentRequests.totalCount);
      } else {
        setPaymentRequests([]);
      }
      setFetchLoader(false);
    } catch (error) {
      toast.error("Error fetching payment requests");
      setFetchLoader(false);
    }
  }
  useEffect(() => {
    fetchPaymentRequests({
      ...pageData,
      searchTerm: debouncedSearchTerm,
    });
  }, [showForm, pageData.page, pageData.limit, debouncedSearchTerm]);

  const handleToggleView = (data) => {
    setShowForm(data);
  };

  const onRowClickGoToEdit = (selectedData) => {
    handleViewSupBuyPayment(selectedData);
  };

  const handleRowClick = (rowData, rowMeta) => {
    let selectedId = rowData[0];
    let selectedPaymentRequest = paymentRequests?.filter(
      (paymentRequest) => paymentRequest?.paymentId === selectedId
    )[0];

    onRowClickGoToEdit(selectedPaymentRequest);
  };

  const handleViewSupBuyPayment = (data) => {
    dispatch({
      type: "SET_SELECTED_SUPPLIER_PAYMENT",
      payload: data,
    });
    history.push("/app/editSupplierPayment");
  };

  const handleSearchChange = (searchText) => {
    setSearchTerm(searchText);
    setPageData((prevState) => ({
      ...prevState,
      page: 1,
    }));
  };

  return (
    <>
      <div className={classes.root}>
        <SectionHeader
          title={showForm ? "New Request" : "Supplier payments"}
          buttonText={showForm ? "All Requests" : "New"}
          buttonAction={() => setShowForm((prev) => !prev)}
          buttonStyle={{ margin: "15px 10px" }}
        />

        {showForm ? (
          <PaymentRequestForm toggleView={handleToggleView} />
        ) : (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              {tabData.map((item, key) => {
                return (
                  <Tab
                    label={item.label}
                    {...a11yProps(item.index)}
                    classes={{ root: classes.tab }}
                    key={key}
                  />
                );
              })}
            </Tabs>
            {paymentRequests &&
              paymentRequests.length > 0 &&
              paymentRequests.map((item, key) => {
                const statusStyle = getStatusStyle(item.status);
                const row = [
                  item?.paymentId,
                  moment(item?.date).format("DD-MM-YYYY"),
                  item?.paymentDetails?.accountHolderName
                    ? item?.paymentDetails?.accountHolderName
                    : "N/A",
                  formatIndianCurrency(item?.amount),
                  item?.paymentFrom,
                  item?.raisedBy[0]?.username,
                  <span style={statusStyle}>{item?.status}</span>,
                ];
                if (item?.status === "Pending") {
                  pendingRequests.push(row);
                }
                if (data?._id === item?.raisedBy[0]?._id) {
                  myRequests.push(row);
                }
                allRequests.push(row);
              })}

            {tabData.map((item, key) => {
              return (
                <TabPanel value={value} index={item.index} key={key}>
                  <Table
                    data={item.data}
                    header={paymentTableHeader}
                    name={""}
                    options={{
                      ...tableOptions(
                        fetchLoader,
                        rowsPerPageOptions,
                        setPageData,
                        handleRowClick,
                        "Supplier Payments.csv",
                        totalPages,
                        pageData.page,
                        handleSearchChange
                      ),
                      tableBodyHeight: "550px",
                    }}
                  />
                </TabPanel>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}
