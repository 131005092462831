import React, { useEffect, useState } from "react";
import { Tabs, Tab, Card, CircularProgress } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import Table from "../../../components/Table/Table";
import LeaveRequestForm from "./LeaveRequestForm";
import useStyles from "../styles";
import {
  actionOnLeaveRequest,
  getAllLeaveRequests,
  getEmployeeDashboardData,
} from "../InternalFormState";
import LeaveActionModal from "../components/LeaveActionModal";
import {
  TabPanel,
  a11yProps,
  leaveTableHeader,
  getStatusStyle,
} from "../components/config/tableHelper";
import SectionHeader from "../components/SectionHeader";
import { tableOptions } from "../components/config/tableConfig";
import toast, { Toaster } from "react-hot-toast";

export default function TabPanelLeaveRequest() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const data = useSelector((state) => state.userdata.getData);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [employeeLeaveData, setEmployeeLeaveData] = useState({});
  const [loader, setLoader] = useState(false);

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);

  useEffect(() => {
    const maxRowsPerPage = totalPages || 10;
    const dynamicRowsPerPageOptions = [10, 20, 50].filter(
      (option) => option <= maxRowsPerPage
    );
    if (!dynamicRowsPerPageOptions.includes(maxRowsPerPage)) {
      dynamicRowsPerPageOptions.push(maxRowsPerPage);
    }
    setRowsPerPageOptions(dynamicRowsPerPageOptions);
  }, [totalPages]);

  let tabData = [];
  let myRequests = [];
  let allRequests = [];
  let pendingRequests = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (
    data &&
    data.roles.some((item) => "user" === item) &&
    !data.roles.some((item) => "Approver" === item)
  ) {
    tabData = [{ label: "My Requests", index: 0, name: "", data: myRequests }];
  } else {
    tabData = [
      { label: "All", index: 0, name: "", data: allRequests },
      { label: "Pending", index: 1, name: "", data: pendingRequests },
    ];
  }
  //modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  async function fetchLeaveRequests(data) {
    setFetchLoader(true);
    try {
      const allLeaveRequests = await getAllLeaveRequests(data);
      if (allLeaveRequests.status == "200") {
        setLeaveRequests(allLeaveRequests.data);
        setTotalPages(allLeaveRequests.totalCount);
      } else {
        setLeaveRequests([]);
      }
      setFetchLoader(false);
    } catch (error) {
      setFetchLoader(false);
    }
  }

  async function getTheEmployeeDashboardData({ selectedYear, userId }) {
    setLoader(true);
    try {
      const data = {
        year: selectedYear,
        userId,
      };
      const response = await getEmployeeDashboardData(data);
      if (response.status === "200") {
        setEmployeeLeaveData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error("An error occurred. Please try again.");
    }
  }

  useEffect(() => {
    setIsModalOpen(false);
    getTheEmployeeDashboardData({
      selectedYear: currentYear,
      userId: data?._id,
    });
    fetchLeaveRequests(pageData);
  }, [showForm, pageData]);

  const handleStatusChange = (newStatus, rejectionReason) => {
    if (
      newStatus === selectedData.status &&
      selectedData.reasonForRejection == rejectionReason
    ) {
      return;
    }
    takeActionOnLeaveRequest(selectedData, newStatus, rejectionReason);
  };

  async function takeActionOnLeaveRequest(
    formData,
    newStatus,
    rejectionReason
  ) {
    try {
      let updatedFormData = {
        ...formData,
        status: newStatus,
        raisedBy: formData[0]?._id,
      };

      if (newStatus === "Rejected" && rejectionReason !== "") {
        updatedFormData.reasonForRejection = rejectionReason;
      }

      const result = await actionOnLeaveRequest(updatedFormData);
      if (result.status === "200") {
        toast.success("Successfully updated!");
        fetchLeaveRequests(pageData);
      }
    } catch (error) {
      toast.error("An error occured try again");
    }
  }

  const handleToggleView = (data) => {
    setShowForm(data);
  };

  const handleRowClick = (rowData, rowMeta) => {
    let selectedId = rowData[0];
    let selectedLeave = leaveRequests?.filter(
      (leaveReq) => leaveReq?.leaveId === selectedId
    )[0];
    getTheEmployeeDashboardData({
      selectedYear: currentYear,
      userId: selectedLeave.raisedBy[0]?._id,
    });
    toggleModal();
    setSelectedData(selectedLeave);
  };

  return (
    <>
      <Toaster />
      <div className={classes.root}>
        <SectionHeader
          title={showForm ? "New Request" : "Leave requests"}
          buttonText={showForm ? "All Requests" : "New"}
          buttonAction={() => setShowForm((prev) => !prev)}
          buttonStyle={{ margin: "15px 10px" }}
        />
        {showForm ? (
          <LeaveRequestForm toggleView={handleToggleView} />
        ) : (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              {tabData.map((item, key) => {
                return (
                  <Tab
                    label={item.label}
                    {...a11yProps(item.index)}
                    classes={{ root: classes.tab }}
                    key={key}
                  />
                );
              })}
            </Tabs>
            {
              // eslint-disable-next-line
              leaveRequests &&
                leaveRequests.length > 0 &&
                leaveRequests.map((item, key) => {
                  const statusStyle = getStatusStyle(item.status);
                  const row = [
                    item?.leaveId,
                    moment(item?.createdAt).format("DD-MM-YYYY"),
                    item?.selectedLeaveType,
                    // item?.startDate,
                    // item?.endDate,
                    item?.noOfDays ? item?.noOfDays : "N/A",

                    item?.raisedBy[0]?.username,
                    <span style={statusStyle}>{item?.status}</span>,
                  ];
                  if (item?.status === "Pending") {
                    pendingRequests.push(row);
                  }
                  if (data?._id === item?.raisedBy[0]._id) {
                    myRequests.push(row);
                  }
                  if (data?._id === item?.raisedBy[0]._id) {
                  }
                  allRequests.push(row);
                })
            }

            {/* {loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "16px 0",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <CircularProgress />
                Loading...
              </div>
            ) : (
              <div style={{ marginTop: "10px" }}>
                {data && data.roles.some((item) => "user" === item) && (
                  <LeaveDash
                    leaveData={employeeLeaveData}
                    showLeavePolicy={true}
                  />
                )}
              </div>
            )} */}

            {tabData.map((item, key) => {
              return (
                <>
                  <TabPanel value={value} index={item.index} key={key}>
                    <Table
                      data={item.data}
                      header={leaveTableHeader}
                      name={""}
                      options={{
                        ...tableOptions(
                          fetchLoader,
                          rowsPerPageOptions,
                          setPageData,
                          handleRowClick,
                          "Leave Requests.csv",
                          totalPages,
                          pageData.page
                        ),
                        tableBodyHeight: "550px",
                      }}
                    />
                  </TabPanel>
                </>
              );
            })}
          </>
        )}
      </div>

      <LeaveActionModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        data={selectedData}
        onStatusChange={handleStatusChange}
        employeeLeaveData={employeeLeaveData}
      />
    </>
  );
}
