import React from "react";

import {
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import { Typography } from "../../../components/Wrappers/Wrappers";

const BasicDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fields = [
    { label: t("customer_company_name"), name: "companyName", type: "text" },
    { label: t("mpr_contact_person"), name: "contactName", type: "text" },
    { label: t("common_email"), name: "email", type: "email" },
    { label: t("common_mobile_no"), name: "mobile", type: "number" },
    { label: t("common_pan"), name: "pan_no", type: "pan" },
    { label: t("common_gst"), name: "taxNo", type: "gst" },
    {
      lable: t("common_notification"),
      name: "notificationAllowed",
      type: "checkbox",
    },
  ];
  const options = [
    { label: "Just you", value: "1" },
    { label: "2-9", value: "2-9" },
    { label: "10-99", value: "10-99" },
    { label: "100-299", value: "100-299" },
    { label: "300+", value: "300+" },
  ];

  return (
    <>
      <Typography
        variant="h3"
        mb={1}
        sx={{ fontWeight: "bold", textAlign: "left", marginTop: "1rem" }}
      >
        {t("Add Customer")}
      </Typography>
      {fields.map((item, key) => (
        <React.Fragment key={key}>
          {(() => {
            switch (item.type) {
              case "text":
                return (
                  <Controller
                    key={key}
                    control={control}
                    name={item.name}
                    rules={{
                      required: "Required Field",
                      minLength: 3,
                    }}
                    render={({ field }) => (
                      <TextField
                        id="first-name"
                        label={item.label}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        {...field}
                        inputProps={{
                          onKeyPress: (e) => {
                            if (
                              item.name === "contactName" &&
                              /\d/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          },
                        }}
                        error={Boolean(errors?.[item.name])}
                        helperText={
                          errors[item.name]?.type === "required"
                            ? errors[item.name]?.message
                            : errors[item.name]?.type === "minLength"
                              ? "Must have a minimum of 3 characters"
                              : ""
                        }
                      />
                    )}
                  />
                );
              case "abbreviation":
                return (
                  <Controller
                    control={control}
                    name={item.name}
                    rules={{
                      required: item.label + " is required.",
                      minLength: 3,
                      maxLength: 5,
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="standard"
                        id="first-name"
                        label={item.label}
                        fullWidth
                        margin="normal"
                        {...field}
                        error={Boolean(errors?.[item.name])}
                        helperText={
                          errors[item.name]?.type === "required"
                            ? errors[item.name]?.message
                            : errors[item.name]?.type === "minLength"
                              ? item.label + " must have minimum 3 charecters"
                              : errors[item.name]?.type === "maxLength"
                                ? item.label +
                                  " do not contain more than 5 charecters"
                                : ""
                        }
                      />
                    )}
                  />
                );
              case "number":
                return (
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={3} xs={3}>
                      <Controller
                        control={control}
                        name="countryCode"
                        rules={{
                          required: "Required Field",
                          pattern: {
                            value: /^\+\d{1,4}$/,
                            message:
                              "Must start with '+' & have 1 to 4 digits..",
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="standard"
                            label={t("Code")}
                            fullWidth
                            margin="normal"
                            {...field}
                            error={Boolean(errors?.["countryCode"])}
                            helperText={
                              errors["countryCode"]?.type === "required"
                                ? errors["countryCode"]?.message
                                : errors.countryCode?.type === "pattern"
                                  ? errors.countryCode.message
                                  : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={8} md={8} sm={9} xs={9}>
                      <Controller
                        control={control}
                        name={item.name}
                        defaultValue=""
                        rules={{
                          // required: "Required Field",
                          pattern: /^\d{10}$/,
                        }}
                        render={({ field }) => (
                          <TextField
                            label={item.label}
                            fullWidth
                            variant="standard"
                            margin="normal"
                            {...field}
                            inputMode="numeric"
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/\D/, "")
                                .slice(0, 10);
                            }}
                            error={Boolean(errors?.[item.name])}
                            helperText={
                              errors[item.name]?.type === "required"
                                ? errors[item.name]?.message
                                : errors[item.name]?.type === "pattern"
                                  ? "Invalid mobile number"
                                  : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              case "gst":
                return (
                  <Controller
                    control={control}
                    name={item.name}
                    render={({ field }) => (
                      <TextField
                        variant="standard"
                        id="first-name"
                        label={item.label}
                        fullWidth
                        margin="normal"
                        {...field}
                      />
                    )}
                  />
                );
              case "pan":
                return (
                  <Controller
                    control={control}
                    name={item.name}
                    rules={{
                      pattern: {
                        value: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
                        message: "Invalid PAN Card Number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="standard"
                        id="first-name"
                        label={item.label}
                        fullWidth
                        margin="normal"
                        {...field}
                      />
                    )}
                  />
                );
              case "email":
                return (
                  <Controller
                    control={control}
                    name={item.name}
                    // eslint-disable-next-line
                    rules={{
                      required: "Required Field",
                      pattern:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="standard"
                        label={item.label}
                        fullWidth
                        margin="normal"
                        {...field}
                        error={Boolean(errors?.[item.name])}
                        helperText={
                          errors[item.name]?.type === "required"
                            ? errors[item.name]?.message
                            : errors[item.name]?.type === "pattern"
                              ? "Invalid email id"
                              : ""
                        }
                      />
                    )}
                  />
                );
              case "checkbox":
                return (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "0.5rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    <Controller
                      control={control}
                      name={item.name}
                      defaultValue={false}
                      render={({ field }) => (
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                          }}
                        >
                          <Checkbox
                            {...field}
                            checked={!field.value} // Inverse the visual state
                            onChange={(e) => field.onChange(!e.target.checked)} // Inverse the value when changed
                            name={item.name}
                          />
                          <Typography>{t("common_notification")}</Typography>
                        </Grid>
                      )}
                    />
                  </Grid>
                );
              case "radio":
                return (
                  <>
                    {" "}
                    <FormLabel component="legend" className={classes.label}>
                      {item.label}
                    </FormLabel>
                    <Controller
                      control={control}
                      name={item.name}
                      rules={{ required: "No of Employees is required." }}
                      render={({ field }) => (
                        <>
                          <RadioGroup
                            name={item.name}
                            {...field}
                            error={Boolean(errors?.[item.name])}
                          >
                            {options.map((option, key) => {
                              return (
                                <FormControlLabel
                                  value={option.value}
                                  control={<Radio color="primary" />}
                                  label={option.label}
                                />
                              );
                            })}
                          </RadioGroup>
                          <FormHelperText style={{ color: "#f44336" }}>
                            {errors[item.name]?.message}
                          </FormHelperText>
                        </>
                      )}
                    />{" "}
                  </>
                );
              default:
                return <></>;
            }
          })()}
        </React.Fragment>
      ))}
    </>
  );
};

export default BasicDetails;
